import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import Alert from 'react-s-alert'
import { space as styledSpace } from 'styled-system'
import moment from 'moment-timezone'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import ClipLoader from 'react-spinners/ClipLoader'
import { Scrollbars } from 'react-custom-scrollbars-2'
import Modal from '@material-ui/core/Modal'
import twitter from 'twitter-text'
import { useDropzone } from 'react-dropzone'
import useDrivePicker from 'react-google-drive-picker-no-default-scope'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import {
  ACCESS_MANAGE,
  DEFAULT_TIME_ZONE,
  ERROR_MESSAGE,
  FACEBOOK,
  FEATURE_ADD_TO_QUEUE,
  FEATURE_BULK_SCHEDULING,
  FEATURE_QUEUE_LABELS,
  GOOGLE,
  IMAGE,
  IMAGE_DYNAMIC,
  DOCUMENT,
  INSTAGRAM,
  LINKEDIN,
  MAX_IMAGE_SIZE,
  MAX_IMAGE_SIZE_TEXT,
  MAX_VIDEO_SIZE,
  MAX_VIDEO_SIZE_TEXT,
  PERMISSION_PUBLISH,
  PERMISSION_IDEAS,
  PERMISSION_ERRORS,
  PINTEREST,
  PLAN_UPGRADE,
  PROFILE_STATUSES,
  REDDIT,
  TUMBLR,
  TIKTOK,
  SNAPCHAT,
  TWITTER,
  VIDEO,
  VIDEO_DYNAMIC,
  VIMEO,
  YOUTUBE,
  MAX_DOCUMENT_SIZE_TEXT,
  MAX_DOCUMENT_SIZE,
  VISTAPAGE,
  THREADS,
  PROFILE_TYPE_TWITTER_FREE,
} from 'consts'
import { isValidInstagramHashtag, isValidInstagramUsername, countAlertTimeout } from 'helpers'
import withConfirm from 'utils/withConfirm'
import { checkIfPlanHasFeatureEnabled, hasEntitiesAccess, hasGlobalAccessManage } from 'utils/feature'
import { H4, Text } from 'components/atoms/Typography'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import Image, { default as ImageComponent } from 'components/atoms/Image'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import Input from 'components/atoms/Input'
import Link from 'components/atoms/Link'
import Icon from 'components/atoms/Icon'
import Divider from 'components/atoms/Divider'
import DropdownMenu from 'components/molecules/DropdownMenu'
import Tooltip from 'components/molecules/Tooltip'
import DropDown from 'shared/DropDown'
import { getProfileFlavor } from 'routes/Settings/Profiles/helpers'
import EntitySelectorWrapper from '../EntitySelectorWrapper'
import BulkEditPostModal from './BulkEditPostModal'
import BulkSubmitPostModal from './BulkSubmitPostModal'
import Table from './BulkPostsTable'
import {
  APPROVED,
  BLOCKED_PUBLISHING_SOCIAL_PROFILES,
  BULK_POST_TYPE_ARTICLE,
  BULK_POST_TYPE_IMAGE,
  BULK_POST_TYPE_VIDEO,
  BULK_POST_TYPE_DOCUMENT,
  BULK_POST_TYPE_MIXED_MEDIA,
  CUSTOM_TYPE,
  DATE_FORMAT,
  DEFAULT_QUEUE_LABEL,
  DRAFT,
  IDEA,
  IMAGE_EXTENSIONS,
  IMAGE_MIME_TYPES,
  IMPORT_FROM_CSV,
  IMPORT_FROM_INSTAGRAM_HASHTAG,
  IMPORT_FROM_INSTAGRAM_USER,
  IMPORT_FROM_MULTIPLE_MEDIA,
  IMPORT_FROM_RSS,
  IMPORT_FROM_SEARCH_CONTENT,
  INSTAGRAM_REG_EXP,
  LOCAL_STORAGE_BULK_POST_MODAL,
  NETWORK_LIMITS,
  PER_CSV,
  POST_IMAGE,
  POST_LINK,
  POST_MEDIA_CLASS_NAME,
  POST_VIDEO,
  POST_DOCUMENT,
  POST_MIXED_MEDIA,
  PUBLISH_AS_REELS,
  QUEUE_LAST,
  QUEUE_NEXT,
  ROUTE_BULK_CSV,
  ROUTE_BULK_RSS,
  ROUTE_CONTENT,
  ROUTE_MEDIA,
  ROUTE_MENTIONS,
  ROUTE_PUBLISH,
  ROUTE_QUEUE,
  ROUTE_VARIABLE,
  URL_REGEXP,
  VIDEO_EXTENSIONS,
  VIDEO_MIME_TYPES,
  DOCUMENT_EXTENSIONS,
  DOCUMENT_MIME_TYPES,
  PUBLISHING_OPTION_NOTIFICATION,
  PUBLISH_AS_CAROUSEL,
  ROUTE_BULK_PROCESS,
} from '../../consts'
import CalendarItemTooltip from '../CalendarItemTooltip'
import SelectedProfilesSlider from '../SelectedProfilesSlider'
import BulkSocialProfilesCustomizationsModal from './BulkSocialProfilesCustomizationsModal'
import {
  defaultValuesForPost,
  dropboxPickerConfiguration,
  getPublicationDataFromLocalStorage,
  googleDrivePickerConfiguration,
  mentionsTransformer,
  oneDrivePickerConfiguration,
  setPublicationDataToLocalStorageDebounce,
  uploadMediaToServerByFormDataHelper,
  variableTransformer,
  getSelectedProfileGroupsAndTimezone,
  formatTimezoneToGMT,
  deHibernateMedias,
  collectPostAttachments,
  collectAdvocacyDataBeforePublishing,
} from '../../helpers'
import MediaLibraryModal from '../MediaLibraryModal'
import MediaDiscoverModal from '../MediaDiscoverModal'
import MediaSettings from '../../../Media/MediaSettings'
import MediaUploadDummy from './MediaUploadDummy'
import { handleMediaFileChange } from '../../../Media/helper'

const StyledDialogOverlay = styled(Modal)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483001 !important;
    > * {
      &:first-child {
        background: none !important;
      }
    }
  }
`

const StyledDialogContent = styled(Flex)`
  &&& {
    position: relative;
    max-width: 100%;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: 100%;
    flex-direction: column;
    background-color: ${COLOR_CONSTANTS.WHITE};
  }
`

const StyledWarningWrapper = styled(Flex)`
  background-color: ${({ color }) => transparentize(0.9, color || COLOR_CONSTANTS.FLUORESCENT_RED)};
  border-radius: 3px;
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  background-color: ${COLOR_CONSTANTS.BOYSENBERRY_SHADOW};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  height: 100%;
  overflow: hidden;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const StyledFlex = styled(Flex)`
  border-bottom: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
`

const StyledImportText = styled(Text)`
  text-align: center;
  color: ${colors.secondaryText};
`

const StyledBox = styled(Box)`
  cursor: pointer;
`

const RightColumnWrapper = styled(Flex)`
  border-left: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  border-right: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
`

const StyledList = styled.ul`
  list-style: none;
  padding-left: ${space.l};
  margin: 0;
  > li::before {
    content: '\\2022';
    color: ${COLOR_CONSTANTS.FLUORESCENT_RED};
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`

const AddMediaWrapper = styled(Flex)`
  cursor: pointer;
`

const StyledCopyImage = styled(Image)`
  transform: scaleX(-1);
`

const StyledMenuItemWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.BOYSENBERRY_SHADOW};
`

const StyledLearnMore = styled(Text)`
  text-decoration: none;
`

const StyledFilesWrapper = styled(Flex)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  background-color: rgba(39, 40, 49, 0.3);
  z-index: 2147483002;
  transition: border 0.24s ease-in-out;
  border: 2px dashed ${COLOR_CONSTANTS.WHITE};
  visibility: hidden;
  ${({ show }) => show && `visibility: visible;`}
`

const QuestionMarkOutlined = styled(Icon.QuestionMarkOutlined)`
  fill: ${colors.secondaryText};
  cursor: pointer;
`

const MEDIA_LIBRARY_MODAL_OPTIONS = {
  isOpen: false,
  type: null,
  postMedias: [],
}

const MEDIA_DISCOVER_MODAL_OPTIONS = {
  isOpen: false,
  type: null,
  postMedias: [],
}

const MEDIA_SETTINGS_MODAL_OPTIONS = {
  isOpen: false,
  uploadedMedias: [],
}

const BULK_EDIT_POST_MODAL_OPTIONS = {
  isOpen: false,
  postForEdit: {},
}

const DEFAULT_POST_SUBMIT_MODAL_OPTIONS = {
  isOpen: false,
  numberOfPosts: 0,
  numberOfIdeas: 0,
}

const BLOCKED_SOCIAL_PROFILES = [...[YOUTUBE, VIMEO, TIKTOK, SNAPCHAT], ...BLOCKED_PUBLISHING_SOCIAL_PROFILES]

const MAX_CSV_SIZE = 2097152000

const CSV_EXAMPLE_LINK = '/files/bulk.csv'

const PUBLISHING_TIME = [
  {
    label: 'Schedule per CSV',
    value: PER_CSV,
    type: IMPORT_FROM_CSV,
    description: 'Publishing time is taken from your CSV settings.',
  },
  { label: 'Save as draft', value: DRAFT, description: 'Save your posts as draft.' },
  {
    label: 'Save as idea',
    value: IDEA,
    description: `'Save your posts as ideas.`,
  },
  {
    label: 'Queue last',
    value: QUEUE_LAST,
    description: `'Queue last' will add this post to the bottom of your queue to be published next. You can view and change your publishing queues by going to Publishing settings.`,
  },
  {
    label: 'Queue next',
    value: QUEUE_NEXT,
    description: `'Queue next' will add this post to the top of your queue to be published next. You can view and change your publishing queues by going to Publishing settings.`,
  },
]

const MENU_ITEMS = [
  {
    title: 'Import a CSV file',
    description: 'Prepare your posts in a spreadsheet and upload them all together.',
    imageComponent: <Image width="20px" height="20px" src="/assets/file_csv.svg" />,
    learnMoreLink: 'https://support.vistasocial.com/hc/en-us/articles/4411200242203-Bulk-publishing',
    buttonText: 'Import from CSV',
    type: IMPORT_FROM_CSV,
  },
  {
    title: 'Import a blog',
    description:
      'Pick the latest articles from a blog or a website. This option is only available for websites providing a RSS feed.',
    imageComponent: <Icon.Link width="20px" height="20px" fill={COLOR_CONSTANTS.COSMIC_ENERGY} />,
    learnMoreLink: 'https://support.vistasocial.com/hc/en-us/articles/4411200242203-Bulk-publishing',
    buttonText: 'Import from RSS',
    type: IMPORT_FROM_RSS,
  },
  {
    title: 'Import latest news',
    description: 'Find great news articles based on topics your audience might be interested in.',
    imageComponent: <Icon.News width="20px" height="20px" fill={COLOR_CONSTANTS.COSMIC_ENERGY} />,
    learnMoreLink: 'https://support.vistasocial.com/hc/en-us/articles/4411191874331-Finding-great-social-media-content',
    buttonText: 'Find news',
    type: IMPORT_FROM_SEARCH_CONTENT,
  },
  {
    title: 'Import Instagram hashtag',
    description: 'Find trending content based on an Instagram hashtag and import it for publishing.',
    imageComponent: <Icon.HashTag width="20px" height="20px" fill={COLOR_CONSTANTS.COSMIC_ENERGY} />,
    learnMoreLink: '',
    buttonText: 'Hashtag search',
    type: IMPORT_FROM_INSTAGRAM_HASHTAG,
  },
  {
    title: 'Import Instagram user',
    description: 'Find content by a specific Instagram user and import it for publishing.',
    imageComponent: <Image width="20px" height="20px" src="/assets/avatar.svg" />,
    learnMoreLink: '',
    buttonText: 'User search',
    type: IMPORT_FROM_INSTAGRAM_USER,
  },
  {
    title: 'Import images, videos and documents',
    description: 'Import and schedule multiple images, videos and documents.',
    imageComponent: <StyledCopyImage width="20px" height="20px" src="/assets/copy.svg" />,
    learnMoreLink: '',
    buttonText: 'Import media',
    type: IMPORT_FROM_MULTIPLE_MEDIA,
  },
]

const clearFileName = (name) => {
  if (name) {
    return name.substring(0, name.lastIndexOf('.'))
  }
  return ''
}

const BulkModal = ({
  user,
  isOpen,
  handleDismiss,
  entities,
  confirm,
  dataForCustomizations,
  handleModalSuccessCallback,
  handleClickReloadCalendar,
  specialWhenToPublish,
  ...props
}) => {
  const tooltipRef = useRef(null)
  const inputCSVFileRef = useRef(null)
  const inputImageFileRef = useRef(null)
  const inputVideoFileRef = useRef(null)
  const inputDocumentFileRef = useRef(null)
  const searchLatestNewsInputRef = useRef(null)
  const searchInstagramInputRef = useRef(null)

  const {
    video_processing: user_settings_video_processing = true,
    image_processing: user_settings_image_processing = true,
  } = user.settings || {}

  const [step, setStep] = useState(1)
  const [importFrom, setImportFrom] = useState(null)
  const [selectedProfiles, setSelectedProfiles] = useState([])
  const [selectedProfilesForEntitySelector, setSelectedProfilesForEntitySelector] = useState([])
  const [selectedEntities, setSelectedEntities] = useState([])
  const [selectedTimezone, setSelectedTimezone] = useState(DEFAULT_TIME_ZONE)
  const [listOfPosts, setListOfPosts] = useState([])
  const [listOfErrors, setListOfErrors] = useState([])
  const [rssUrl, setRSSUrl] = useState('')
  const [isSearchingForPosts, setIsSearchingForPosts] = useState(false)
  const [bulkEditPostModalOptions, setBulkEditPostModalOptions] = useState({ ...BULK_EDIT_POST_MODAL_OPTIONS })
  const [postSubmitModalOptions, setPostSubmitModalOptions] = useState({ ...DEFAULT_POST_SUBMIT_MODAL_OPTIONS })
  const [whenToPublish, setWhenToPublish] = useState(QUEUE_LAST)
  const [isBulkSocialProfilesCustomizationsModalOpen, setIsBulkSocialProfilesCustomizationsModalOpen] = useState(null)
  const [isMediaUploadDummyModalOpen, setIsMediaUploadDummyModalOpen] = useState(null)
  const [customizationData, setCustomizationData] = useState({})
  const [mediaLibraryOptions, setMediaLibraryOptions] = useState({ ...MEDIA_LIBRARY_MODAL_OPTIONS })
  const [mediaDiscoverOptions, setMediaDiscoverOptions] = useState({ ...MEDIA_DISCOVER_MODAL_OPTIONS })
  const [mediaSettingsOptions, setMediaSettingsOptions] = useState({ ...MEDIA_SETTINGS_MODAL_OPTIONS })
  const [agencyMentionsOptions, setAgencyMentionsOptions] = useState({ isLoading: false, data: [] })
  const [entitySelectorKey, setEntitySelectorKey] = useState(moment().valueOf())
  const [featuresEnabled, setFeaturesEnabled] = useState({
    [FEATURE_BULK_SCHEDULING]: { enabled: true },
    [FEATURE_ADD_TO_QUEUE]: { enabled: true },
    [FEATURE_QUEUE_LABELS]: { enabled: true },
  })
  const [dataToLocalStorageOptions, setDataToLocalStorageOptions] = useState({
    canSave: false,
    lastTimeSaved: 0,
    lastTimeSavedMessage: '',
  })
  const [isMediaUploading, setIsMediaUploading] = useState(false)
  const [uploadingMediasAmount, setUploadingMediasAmount] = useState(0)
  const [isUpdatingPosts, setIsUpdatingPosts] = useState(false)
  const [uploadedMediasViaInput, setUploadedMediasViaInput] = useState([])
  const [usedQueueLabels, setUsedQueueLabels] = useState([])
  const [selectedQueueLabel, setSelectedQueueLabel] = useState(null)
  const [savedVariables, setSavedVariables] = useState({ list: [], profile_variables: {} })
  const [openPicker, authResponse] = useDrivePicker()
  const [savedGoogleDocsAuthResponse, setSavedGoogleDocsAuthResponse] = useState({})
  const [sharedDocsForUpload, setSharedDocsForUpload] = useState([])
  const [workflow, setWorkflow] = useState([])
  const [processText, setProcessText] = useState('')

  const numberOfSelectedPosts = listOfPosts.filter(({ selected }) => selected).length

  const getMentions = async () => {
    let response = []
    try {
      setAgencyMentionsOptions({ ...{ isLoading: true, data: [] } })

      response = await request({
        method: 'GET',
        path: ROUTE_MENTIONS,
      })

      if (!response || response.error) {
        response = []
      }
    } catch (error) {
      errorHelper({ error, componentName: BulkModal.displayName, functionName: 'getMentions' })
    } finally {
      setAgencyMentionsOptions({ ...{ isLoading: false, data: response } })
    }
  }

  const getSavedVariables = async ({ profiles = [] }) => {
    try {
      const response = await request({
        path: `${ROUTE_VARIABLE}?profiles=${profiles.map(({ id }) => id).join(',')}`,
      })
      const { error, list = [], profile_variables } = response || {}
      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        setSavedVariables({ ...{ list, profile_variables } })
      }
    } catch (error) {
      errorHelper({ error, componentName: BulkModal.displayName, functionName: 'getSavedVariables' })
    }
  }

  const getQueueLabels = async () => {
    try {
      const response = await request({
        method: 'POST',
        path: `${ROUTE_QUEUE}/labels`,
        body: {
          targets: selectedProfiles.map(({ id, entityId }) => ({
            profile_gid: id,
            entityId,
          })),
        },
      })
      if (response && !response.error) {
        const { labels = [] } = response || {}
        setUsedQueueLabels([DEFAULT_QUEUE_LABEL, ...labels.map((label) => ({ value: label, label: `${label} label` }))])
      }
      // eslint-disable-next-line no-empty
    } catch (error) {
      errorHelper({ error, componentName: BulkModal.displayName, functionName: 'getQueueLabels' })
    }
  }

  useEffect(() => {
    if (isOpen) {
      getPublicationDataFromLocalStorage({
        key: LOCAL_STORAGE_BULK_POST_MODAL,
        message: `We have auto saved your bulk posts. Do you want to load them?`,
        confirm,
        setData: ({ importFrom, listOfPosts, listOfErrors, whenToPublish }) => {
          setImportFrom(importFrom)
          setListOfPosts([...listOfPosts])
          setListOfErrors([...listOfErrors])
          setWhenToPublish(whenToPublish)
          setStep(2)
        },
        setDataToLocalStorageOptions,
      })

      setFeaturesEnabled(
        checkIfPlanHasFeatureEnabled({
          user,
          features: [FEATURE_BULK_SCHEDULING, FEATURE_ADD_TO_QUEUE, FEATURE_QUEUE_LABELS],
        })
      )
      setImportFrom(null)
      setStep(1)
      setListOfPosts([])
      setListOfErrors([])
      setRSSUrl('')

      const profilesWithAccess = props.selectedProfiles.filter(
        ({ entityId }) =>
          !hasEntitiesAccess({
            user,
            permission: PERMISSION_PUBLISH,
            entity_gid: entityId,
            accessLevels: [ACCESS_MANAGE],
          })
      )

      setSelectedProfilesForEntitySelector(profilesWithAccess)
      setSelectedProfiles(profilesWithAccess.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i))
      setSelectedEntities([...props.selectedEntities])
      setCustomizationData({})
      getMentions()
    }
  }, [isOpen])

  useEffect(() => {
    if (
      listOfPosts.length !== 0 &&
      (importFrom === IMPORT_FROM_MULTIPLE_MEDIA ||
        importFrom === IMPORT_FROM_INSTAGRAM_HASHTAG ||
        importFrom === IMPORT_FROM_INSTAGRAM_USER)
    ) {
      if (importFrom === IMPORT_FROM_MULTIPLE_MEDIA) {
        // eslint-disable-next-line no-use-before-define
        if (isMediaUploading && checkIfMediaCanBeAttached({ func: () => true, showAlert: false })) {
          setIsMediaUploading(false)
        }

        if (!mediaSettingsOptions.isOpen && !isMediaUploading) {
          listOfPosts.forEach(({ postImages = [], postVideos = [], postDocuments = [], postComponent }) => {
            let tempId
            let url
            let file
            let type
            if (postComponent === POST_IMAGE) {
              ;({ 0: { url, tempId, file, type = IMAGE } = {} } = postImages)
            } else if (postComponent === POST_VIDEO) {
              ;({ 0: { url, tempId, file, type = VIDEO } = {} } = postVideos)
            } else if (postComponent === POST_DOCUMENT) {
              ;({ 0: { url, tempId, file, type = DOCUMENT } = {} } = postDocuments)
            }

            if (tempId) {
              if ((url || file) && !isMediaUploading) {
                setIsMediaUploading(true)
              }

              if (url) {
                // eslint-disable-next-line no-use-before-define
                uploadMediaToServerByStringData({
                  mediaURL: url,
                  uploadingId: tempId,
                  type,
                })
              } else if (file) {
                // eslint-disable-next-line no-use-before-define
                uploadMediaToServerByFormData({ file, uploadingId: tempId })
              }
            }
          })

          if (dataToLocalStorageOptions.canSave && step === 2) {
            setPublicationDataToLocalStorageDebounce({
              key: LOCAL_STORAGE_BULK_POST_MODAL,
              data: { importFrom, listOfPosts, listOfErrors, whenToPublish },
              dataToLocalStorageOptions,
              setDataToLocalStorageOptions,
            })
          }
        }
      }

      let foundUploadedMedias = []
      for (let i = 0; i < listOfPosts.length; i++) {
        let isMediaUploading = false

        let mediaComponent

        if (listOfPosts[i].postComponent === POST_IMAGE) {
          mediaComponent = 'postImages'
        } else if (listOfPosts[i].postComponent === POST_VIDEO) {
          mediaComponent = 'postVideos'
        } else if (listOfPosts[i].postComponent === POST_DOCUMENT) {
          mediaComponent = 'postDocuments'
        }

        if (mediaComponent && listOfPosts[i][mediaComponent]) {
          for (let j = 0; j < listOfPosts[i][mediaComponent].length; j++) {
            const { tempId, isNew } = listOfPosts[i][mediaComponent][j]
            if (tempId) {
              isMediaUploading = true
              break
            } else if (isNew) {
              foundUploadedMedias.push({ ...listOfPosts[i][mediaComponent][j] })
            }
          }
        }

        if (isMediaUploading) {
          foundUploadedMedias = []
          break
        }
      }

      if (foundUploadedMedias.length !== 0) {
        setMediaSettingsOptions({ ...{ isOpen: true, uploadedMedias: foundUploadedMedias } })
      }
    }
  }, [listOfPosts, isMediaUploading])

  useEffect(() => {
    if (uploadedMediasViaInput.length !== 0) {
      setListOfPosts([...listOfPosts, ...uploadedMediasViaInput])
      setUploadedMediasViaInput([])
    }
  }, [uploadedMediasViaInput])

  useEffect(() => {
    if (step === 2) {
      getQueueLabels()
      if (importFrom === IMPORT_FROM_CSV || importFrom === IMPORT_FROM_RSS) {
        getSavedVariables({ profiles: selectedProfiles })
      }
    }
  }, [selectedProfiles])

  useEffect(() => {
    if (authResponse && authResponse.access_token) {
      setSavedGoogleDocsAuthResponse({ ...{ ...savedGoogleDocsAuthResponse, ...authResponse } })
    }
  }, [authResponse])

  useEffect(() => {
    if (sharedDocsForUpload && sharedDocsForUpload.length !== 0) {
      const { type } = savedGoogleDocsAuthResponse

      const postMedias = []

      listOfPosts.forEach((post) => {
        let data = []

        if (type === POST_IMAGE) {
          data = post.postImages
        } else if (type === POST_VIDEO) {
          data = post.postVideos
        } else if (type === POST_DOCUMENT) {
          data = post.postDocuments
        }

        const { 0: firstMedia } = data || []
        if (firstMedia) {
          postMedias.push(firstMedia)
        }
      })

      //  eslint-disable-next-line no-use-before-define
      handleClickAddMedias({
        selectedMedias: [
          ...postMedias,
          ...sharedDocsForUpload.map(({ url }) => ({
            url: savedGoogleDocsAuthResponse.access_token
              ? `${url}&access_token=${savedGoogleDocsAuthResponse.access_token}`
              : url,
            tempId: Number(
              `${Math.floor(Math.random() * 100)}${Math.floor(Math.random() * 100)}${Math.floor(Math.random() * 100)}`
            ),
          })),
        ],
        postComponent: type,
      })
      setSharedDocsForUpload([])
    }
  }, [sharedDocsForUpload])

  const { firstSelectedEntity } = useMemo(() => {
    let firstSelectedEntity = {}

    if (selectedEntities && selectedEntities.length !== 0) {
      const { selectedEntitiesWithSelectedProfiles } = getSelectedProfileGroupsAndTimezone({
        user,
        selectedEntities,
        selectedProfiles,
      })

      firstSelectedEntity = selectedEntitiesWithSelectedProfiles[0] || {}
    }

    return { firstSelectedEntity }
  }, [selectedEntities, selectedProfiles])

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0 && !isMediaUploading && !mediaSettingsOptions.isOpen) {
      handleMediaFileChange({
        acceptedFiles,
        setUploadingMedias: (medias) => {
          const newListOfPosts = []
          medias.forEach((media) => {
            const { tempId, id, file, type } = media
            const postId = Number(
              `${Math.floor(Math.random() * 100)}${Math.floor(Math.random() * 100)}${Math.floor(Math.random() * 100)}`
            )

            if (type === IMAGE) {
              newListOfPosts.push({
                tempId: postId,
                selected: false,
                postComponent: POST_IMAGE,
                postImages: [{ tempId, id, isNew: true, file, type: IMAGE }],
                postVideos: [],
                postDocuments: [],
                postMixedMedias: [],
              })
            } else if (type === VIDEO) {
              newListOfPosts.push({
                tempId: postId,
                selected: false,
                postComponent: POST_VIDEO,
                postImages: [],
                postVideos: [{ tempId, id, isNew: true, file, type: VIDEO }],
                postDocuments: [],
                postMixedMedias: [],
              })
            } else if (type === DOCUMENT) {
              newListOfPosts.push({
                tempId: postId,
                selected: false,
                postComponent: POST_DOCUMENT,
                postImages: [],
                postVideos: [],
                postDocuments: [{ tempId, id, isNew: true, file, type: DOCUMENT }],
                postMixedMedias: [],
              })
            }
          })
          setUploadedMediasViaInput([...newListOfPosts])
        },
        setUploadingMediasAmount,
      })
    }
  }, [])

  const { getRootProps, isDragActive } = useDropzone({ onDrop })

  const handleSaveSelectedProfiles = async ({ data }) => {
    const { selectedTimezone } = getSelectedProfileGroupsAndTimezone({
      user,
      selectedProfiles: data,
    })

    setSelectedTimezone(selectedTimezone)

    setSelectedProfilesForEntitySelector([...data])
    setSelectedProfiles(data.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i))
  }

  const handleSaveSelectedEntities = async ({ data }) => {
    const { selectedTimezone } = getSelectedProfileGroupsAndTimezone({
      user,
      selectedEntities: data,
    })

    setSelectedTimezone(selectedTimezone)

    setSelectedEntities([...data])
  }

  const handleClickRemoveProfile = ({ id, entityId }) => {
    const updatedProfiles = selectedProfiles.filter((profile) => profile.id !== id || profile.entityId !== entityId)
    handleSaveSelectedProfiles({ data: updatedProfiles })
    setEntitySelectorKey(moment().valueOf())
  }

  const handleClickSelectImport = (type) => {
    setImportFrom(type)
    setListOfPosts([])
    setListOfErrors([])
    setIsMediaUploading(false)

    if (specialWhenToPublish) {
      setWhenToPublish(specialWhenToPublish)
    } else {
      if (type === IMPORT_FROM_CSV) {
        setWhenToPublish(PER_CSV)
      } else {
        setWhenToPublish(QUEUE_LAST)
      }
    }

    setStep(2)
  }

  const handleClickGetRSSPosts = async () => {
    const res = rssUrl.match(URL_REGEXP)
    if (!res) {
      Alert.error(`Invalid RSS url`)
    } else {
      setCustomizationData({})
      setIsSearchingForPosts(true)
      try {
        const response = await request({
          method: 'POST',
          path: ROUTE_BULK_RSS,
          body: { url: rssUrl },
        })

        const { error, data = [] } = response || {}

        if (!response || error) {
          Alert.error(response.error || ERROR_MESSAGE)
        } else {
          handleModalSuccessCallback()
          const newlyUploadedMedias = []

          const posts = data.map(({ message, link, date, media }) => {
            const post = {
              tempId: Number(
                `${Math.floor(Math.random() * 100)}${Math.floor(Math.random() * 100)}${Math.floor(Math.random() * 100)}`
              ),
              postText: message,
              postComponent: POST_LINK,
              link: { url: link, title: message, active: true, needsUpload: true },
              postImages: [],
              postVideos: [],
              postDocuments: [],
              postMixedMedias: [],
              date,
            }

            if (media) {
              const { type, media_gid, picture_url, ...props } = media
              media.id = media_gid

              if (type === IMAGE) {
                post.postComponent = POST_IMAGE

                const mediaData = {
                  id: media_gid,
                  media_gid,
                  url: picture_url,
                  type: IMAGE,
                  isNew: true,
                  ...props,
                }
                post.postImages.push(mediaData)
                newlyUploadedMedias.push(mediaData)
              } else if (type === VIDEO) {
                post.postComponent = POST_VIDEO

                const mediaData = {
                  id: media_gid,
                  media_gid,
                  url: picture_url,
                  type: VIDEO,
                  isNew: true,
                  ...props,
                }
                post.postVideos.push(mediaData)
                newlyUploadedMedias.push(mediaData)
              }
            }

            return post
          })

          setListOfPosts([...posts])

          if (newlyUploadedMedias.length !== 0) {
            setMediaSettingsOptions({ ...{ isOpen: true, uploadedMedias: newlyUploadedMedias } })
          }
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: BulkModal.displayName,
          functionName: 'handleClickGetRSSPosts',
          errorMessage: `Unable to retrieve posts from this website. It either not a valid RSS or a website that does not provide an RSS feed.`,
        })
      } finally {
        setIsSearchingForPosts(false)
      }
    }
  }

  const handleClickFindContent = async () => {
    const { value = '' } = searchLatestNewsInputRef.current
    if (value.length === 0) {
      Alert.error(`Please enter company, product or keyword.`)
    } else {
      setCustomizationData({})
      setIsSearchingForPosts(true)
      try {
        const response = await request({
          path: `${ROUTE_CONTENT}?q=${value}`,
        })
        const { error } = response || {}
        if (!response || error) {
          Alert.error(response.error || ERROR_MESSAGE)
        } else {
          handleModalSuccessCallback()
          setListOfPosts(
            response.map(({ title, url }) => {
              return {
                tempId: Number(
                  `${Math.floor(Math.random() * 100)}${Math.floor(Math.random() * 100)}${Math.floor(
                    Math.random() * 100
                  )}`
                ),
                postText: `${title}\n${url}`,
                link: { url, needsUpload: true, active: true },
                postComponent: POST_LINK,
              }
            })
          )
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: BulkModal.displayName,
          functionName: 'handleClickFindContent',
          errorMessage: `Can't find any articles. Please contact support.`,
        })
      } finally {
        setIsSearchingForPosts(false)
      }
    }
  }

  const handleClickFindMedia = async () => {
    const { value = '' } = searchInstagramInputRef.current

    let error = ''

    const updatedValue = value.replace(INSTAGRAM_REG_EXP, '').trim()

    if (updatedValue) {
      setCustomizationData({})
      setIsSearchingForPosts(true)
      try {
        let path = ''

        if (importFrom === IMPORT_FROM_INSTAGRAM_HASHTAG) {
          if (!isValidInstagramHashtag(`#${updatedValue}`)) {
            error = `Please enter a valid hashtag`
          }

          path = `instagram/hashtag/recent?q=${updatedValue}&type=any`
        } else if (importFrom === IMPORT_FROM_INSTAGRAM_USER) {
          if (!isValidInstagramUsername(`@${updatedValue}`)) {
            error = `Please enter a valid Instagram username`
          }

          path = `instagram/user/recent?q=${updatedValue}&type=any`
        }

        if (!error && path) {
          const response = await request({
            path,
          })

          const { error: responseError, data = [] } = response
          if (!response || responseError) {
            error = responseError || ERROR_MESSAGE
          } else {
            setListOfPosts(
              data.map(({ description, url, username, published, media_type }) => {
                const post = {
                  tempId: Number(
                    `${Math.floor(Math.random() * 100)}${Math.floor(Math.random() * 100)}${Math.floor(
                      Math.random() * 100
                    )}`
                  ),
                  postText: `${description} via ${username ? `@${username}` : 'Instagram'}`,
                  postComponent: media_type.toLowerCase() === VIDEO ? POST_VIDEO : POST_IMAGE,
                  postVideos: [],
                  postImages: [],
                  postDocuments: [],
                  postMixedMedias: [],
                  date_transformed: published,
                }
                const tempId = Number(
                  `${Math.floor(Math.random() * 100)}${Math.floor(Math.random() * 100)}${Math.floor(
                    Math.random() * 100
                  )}`
                )
                if (media_type && media_type.toLowerCase() === VIDEO) {
                  post.postVideos.push({
                    needsUpload: true,
                    videoURL: url,
                    uploadingId: tempId,
                    url,
                    type: VIDEO,
                  })
                } else if (media_type) {
                  post.postImages.push({
                    needsUpload: true,
                    imageURL: url,
                    uploadingId: tempId,
                    url,
                    type: IMAGE,
                  })
                }
                return post
              })
            )
          }
        }
      } catch (e) {
        errorHelper({
          error: e,
          componentName: BulkModal.displayName,
          functionName: 'handleClickFindMedia',
          showAlert: false,
        })
        error = `Can't find anything. Please try again.`
      } finally {
        setIsSearchingForPosts(false)
      }
    } else {
      error = 'Please enter search criteria.'
    }
    if (error) {
      Alert.error(error, { timeout: 5000 })
    }
  }

  const uploadFileToServer = async ({ file, callback }) => {
    const { selectedTimezone } = getSelectedProfileGroupsAndTimezone({ user, selectedEntities, selectedProfiles })

    setCustomizationData({})
    setIsSearchingForPosts(true)
    const body = new FormData()
    body.append('data', file)
    try {
      const response = await request({
        method: 'POST',
        body,
        path: `${ROUTE_BULK_CSV}?timezone=${selectedTimezone}`,
        sendFile: true,
      })

      if (response) {
        const { data: parsed, headers, timezone, error } = response

        if (error) {
          Alert.error(error)
        } else {
          const data = []
          const newlyUploadedMedias = []

          const chunk_size = 10

          let total = 0
          const prs = Number((100 / (parsed.length / chunk_size)).toFixed())

          while (parsed.length) {
            const chunk = parsed.splice(0, chunk_size)

            total += prs
            if (callback) {
              if (total > 100) {
                total = 100
              }
              callback({ text: `${total}%`, total })
            }

            handleModalSuccessCallback()

            const body2 = { data: chunk, headers, timezone }

            const response2 = await request({
              method: 'POST',
              body: body2,
              path: `${ROUTE_BULK_PROCESS}`,
              sendFile: false,
            })

            const { items, errors: errors2, error: error2 } = response2

            if (error2) {
              Alert.error(error2)
            } else if (errors2) {
              setListOfErrors([...errors2])
            }

            const data2 = items.map(({ message, link, publish_at, type, media = [], customizations = {} }) => {
              const item = {
                tempId: Number(
                  `${Math.floor(Math.random() * 100)}${Math.floor(Math.random() * 100)}${Math.floor(
                    Math.random() * 100
                  )}`
                ),
                postText: message,
                link: {},
                postImages: [],
                postVideos: [],
                postDocuments: [],
                postMixedMedias: [],
                defaultPublishAt: publish_at,
                defaultCustomizations: customizations || {},
              }

              if (type === BULK_POST_TYPE_ARTICLE) {
                if (link) {
                  item.postComponent = POST_LINK
                  item.link = { url: link, title: message, active: true }
                }
              } else {
                let updatedType = type

                const media_filtered = media.filter(({ media_gid }) => media_gid)

                const media_types = new Set()

                media_filtered.forEach(({ type }) => {
                  media_types.add(type)
                })

                const allAttachmentsAreOneType = media_types.size <= 1

                if (!allAttachmentsAreOneType) {
                  updatedType = BULK_POST_TYPE_MIXED_MEDIA
                }

                const types = [
                  { type: BULK_POST_TYPE_IMAGE, key: 'postImages', postComponent: POST_IMAGE },
                  { type: BULK_POST_TYPE_VIDEO, key: 'postVideos', postComponent: POST_VIDEO },
                  { type: BULK_POST_TYPE_DOCUMENT, key: 'postDocuments', postComponent: POST_DOCUMENT },
                  { type: BULK_POST_TYPE_MIXED_MEDIA, key: 'postMixedMedias', postComponent: POST_MIXED_MEDIA },
                ]

                for (let i = 0; i < types.length; i++) {
                  const { type, key, postComponent } = types[i]

                  if (updatedType === type) {
                    item.postComponent = postComponent

                    item[key] = media_filtered.map(({ media_gid, picture_url, ...media }) => {
                      const data = {
                        id: media_gid,
                        media_gid,
                        url: picture_url,
                        isNew: true,
                        ...media,
                      }
                      newlyUploadedMedias.push(data)
                      return data
                    })

                    break
                  }
                }
              }

              return item
            })

            for (const elem of data2) {
              data.push(elem)
            }
          }

          if (callback) {
            callback({ text: '', total })
          }

          handleModalSuccessCallback()
          setListOfPosts([...data])

          if (newlyUploadedMedias.length !== 0) {
            setMediaSettingsOptions({ ...{ isOpen: true, uploadedMedias: newlyUploadedMedias } })
          }
        }
      } else {
        Alert.error(ERROR_MESSAGE)
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: BulkModal.displayName,
        functionName: 'uploadFileToServer',
      })
    } finally {
      setIsSearchingForPosts(false)
    }
  }

  const handleUploading = ({ text, total }) => {
    setProcessText(`${text} `)
  }

  const handleCSVFileChange = (event) => {
    if (event.currentTarget.files[0]) {
      const { files: { 0: file } = {} } = event.currentTarget

      const { name, size } = file
      const extension = name.substring(name.lastIndexOf('.'))
      if (extension === '.csv') {
        if (size <= MAX_CSV_SIZE) {
          uploadFileToServer({ file, callback: handleUploading })
        } else {
          Alert.error(`The size of the file must be less than ${MAX_VIDEO_SIZE_TEXT}!`)
        }
      } else {
        Alert.error(`Only .csv files allowed!`)
      }
    }
  }

  const handleClickSelectAllPostsForPublish = ({ type }) => {
    if (type === 'select') {
      listOfPosts.forEach((item) => {
        item.selected = true
      })
    } else {
      listOfPosts.forEach((item) => {
        item.selected = false
      })
    }
    setListOfPosts([...listOfPosts])
  }

  const handleClickEditPost = (item, index) => {
    const { status = DRAFT } = item

    if (status === APPROVED) {
      Alert.error(`This post has already been scheduled. Please make edits from calendar.`, {
        timeout: 5000,
      })
    } else {
      selectedProfiles.forEach(({ id, code }) => {
        if (!item.customizations) {
          item.customizations = {}
        }
        if (!item.customizations[code]) {
          item.customizations[code] = {}
        }

        if (!item.customizations[code][id] && item.defaultCustomizations && item.defaultCustomizations[code]) {
          item.customizations[code][id] = { ...item.defaultCustomizations[code] }
        }
      })

      item.index = index
      item.autoFilledPostText = false
      // eslint-disable-next-line no-use-before-define
      checkIfMediaCanBeAttached({
        func: () => {
          setBulkEditPostModalOptions({
            ...{ isOpen: true, postForEdit: { ...item, agencyMentions: agencyMentionsOptions.data } },
          })
        },
      })
    }
  }

  const handleClickCloseEditPostModal = () => {
    setBulkEditPostModalOptions({ ...BULK_EDIT_POST_MODAL_OPTIONS })
    getSavedVariables({ profiles: selectedProfiles })
  }

  const handleUpdatePost = (post) => {
    const { index } = post
    // eslint-disable-next-line no-use-before-define
    const { post: updatedPost } = handleCheckPostForError(post, customizationData)
    listOfPosts[index] = updatedPost
    setListOfPosts([...listOfPosts])
    handleClickCloseEditPostModal()
    getMentions()
  }

  const handleClickPreviousStep = () => {
    if (!isUpdatingPosts) {
      if (listOfPosts.length !== 0) {
        confirm({
          fn: () => () => {
            setStep(1)
          },
          message: `Your posts won't be saved. Do you want to continue?`,
          action: `Yes, I'm sure`,
          cancel: 'No, cancel',
        })
      } else {
        setStep(1)
      }
    }
  }

  const handleClickCloseModal = () => {
    if (!isUpdatingPosts) {
      if (step === 2) {
        handleClickPreviousStep()
      } else {
        handleDismiss()
      }
    }
  }

  const handleClickClosePostSubmitModal = () => {
    setPostSubmitModalOptions({ ...DEFAULT_POST_SUBMIT_MODAL_OPTIONS })
  }

  const handleUpdateEntities = (body) => {
    for (let i = 0; i < body.length; i++) {
      for (let j = 0; j < selectedEntities.length; j++) {
        if (selectedEntities[j].id === body[i].entity_id) {
          selectedEntities[j].timezone = body[i].timezone
          break
        }
      }
    }

    setSelectedEntities([...selectedEntities])
    props.handleUpdateEntities(body)
  }

  const handleCreatePost = async (data) => {
    let hasError = false
    let publication_ids = []
    let publication_group_id
    try {
      const response = await request({
        method: 'POST',
        body: data,
        path: ROUTE_PUBLISH,
      })

      const { error = '' } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: countAlertTimeout({ text: error || ERROR_MESSAGE }) })
        hasError = true
      } else {
        ;({ publication_ids, publication_group_id } = response)
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: BulkModal.displayName,
        functionName: 'handleCreatePost',
      })
      hasError = true
    }
    return { hasError, publication_id: publication_ids[0], publication_group_id }
  }

  const handleCreateIdea = async ({ data, entity_gids }) => {
    let hasError = false
    try {
      const body = {
        message: data.message || '',
        attachments: data.attachments || [],
        access: [user.id],
        labels: [],
        entity_gids,
        using_ai: false,
      }

      const response = await request({
        path: `idea`,
        method: 'POST',
        body,
      })

      const { error = '' } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        hasError = true
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: BulkModal.displayName,
        functionName: 'handleCreateIdea',
      })
      hasError = true
    }
    return { hasError }
  }

  const handleSubmitPosts = async () => {
    const { selectedTimezone } = getSelectedProfileGroupsAndTimezone({ user, selectedEntities, selectedProfiles })

    selectedProfiles.forEach((profile) => {
      const foundEntity = selectedEntities.find((entity) => entity.id === profile.entityId)
      if (foundEntity) {
        profile.timezone = foundEntity.timezone || DEFAULT_TIME_ZONE
        profile.entityType = foundEntity.type
      }
    })

    const postsForPublish = []

    const ideasForSaving = []

    listOfPosts.forEach((item) => {
      const {
        tempId,
        selected = false,
        postText = '',
        postComponent,
        link,
        labels = [],
        publish_at,
        defaultPublishAt,
        customizations,
        postImages = [],
        postVideos = [],
        postDocuments = [],
        postMixedMedias = [],
        selectedQueueLabel: postSelectedQueueLabel,
        advocacy,
        using_ai = false,
      } = item

      if (selected) {
        const data = {
          message: postText,
          targets: [],
          labels,
          customizations: [],
          using_ai,
        }

        collectAdvocacyDataBeforePublishing({ data, advocacy })

        const attachments = collectPostAttachments({ post: item })

        if (attachments) {
          data.attachments = attachments
        }

        if (link && link.url) {
          if (!link.shorten) {
            link.shorten = { type: 'DEFAULT' }
          }

          data.link = {
            url: link.url,
            title: link.title || '',
            description: link.description || '',
            shorten: link.shorten,
            active: postComponent === POST_LINK,
          }

          if (link.utm_tracking) {
            data.link.tracking = {
              params: link.tracking.params.map(({ name, type, value }) => {
                const data = { name, type: type.value }
                if (data.type === CUSTOM_TYPE) {
                  data.value = value
                }
                return data
              }),
            }

            if (link.save_utm_tracking) {
              data.link.save_utm_tracking = true
              data.link.utmTrackingId = link.utmTrackingId
            }
          } else if (typeof link.utm_tracking !== 'undefined') {
            data.link.tracking = {
              disabled: true,
            }
          }

          if (link.id) {
            data.link.media_gid = link.id

            if (data.idea) {
              data.attachments.push({ media_gid: link.id, type: IMAGE.toUpperCase() })
            }
          }
        } else {
          data.link = null
        }

        selectedProfiles.forEach(({ id, entityId, code, timezone }) => {
          const targetsData = {
            profile_gid: id,
            network: code,
            timezone: selectedTimezone || timezone || DEFAULT_TIME_ZONE,
            labels: [],
          }

          if (
            (publish_at && publish_at !== DRAFT && publish_at !== IDEA) ||
            (defaultPublishAt && whenToPublish === PER_CSV)
          ) {
            const publishDate = publish_at || defaultPublishAt

            if (publishDate) {
              if (publishDate.includes('queue') || publishDate === 'now') {
                targetsData.publish_at = publishDate
                if (
                  publishDate.includes('queue') &&
                  ((postSelectedQueueLabel && postSelectedQueueLabel.value) ||
                    (selectedQueueLabel && selectedQueueLabel.value))
                ) {
                  targetsData.labels.push(
                    postSelectedQueueLabel ? postSelectedQueueLabel.value : selectedQueueLabel.value
                  )
                }
              } else {
                targetsData.publish_at = moment(publishDate).format('YYYY-MM-DD HH:mm')
              }
            } else {
              targetsData.publish_at = null
            }
          } else if (
            whenToPublish !== DRAFT &&
            whenToPublish !== IDEA &&
            (!publish_at || (publish_at !== DRAFT && publish_at !== IDEA))
          ) {
            targetsData.publish_at = whenToPublish

            if (
              whenToPublish.includes('queue') &&
              ((postSelectedQueueLabel && postSelectedQueueLabel.value) ||
                (selectedQueueLabel && selectedQueueLabel.value))
            ) {
              targetsData.labels.push(postSelectedQueueLabel ? postSelectedQueueLabel.value : selectedQueueLabel.value)
            }
          } else if ((!publish_at && whenToPublish === IDEA) || (publish_at && publish_at === IDEA)) {
            data.idea = true
          } else {
            const now = moment.tz(moment(), true, timezone)
            const date = now.format(DATE_FORMAT)
            const hour = now.format('hh')
            const minute = now.format('mm')
            const interval = now.format('A')

            const date_raw = `${date} ${hour}:${minute} ${interval}`
            targetsData.publish_at_raw = date_raw
            targetsData.publish_at = moment(date_raw, `${DATE_FORMAT} hh:mm A`).format(`${DATE_FORMAT} HH:mm`)
            data.draft = true
          }

          targetsData.entity_gid = entityId

          data.targets.push(targetsData)

          const postCustomization = customizations?.[code]?.[id]
            ? JSON.parse(JSON.stringify(customizations[code][id]))
            : {}

          const {
            postImages: customizedPostImages = postImages,
            postVideos: customizedPostVideos = postVideos,
            postDocuments: customizedPostDocuments = postDocuments,
            postMixedMedias: customizedPostMixedMedias = postMixedMedias,
          } = postCustomization

          let customizedPostComponent = postCustomization.postComponent

          if (typeof customizedPostComponent === 'undefined') {
            customizedPostComponent = postComponent
          }

          if (customizations) {
            const { video_processing } = customizations
            if (typeof video_processing !== 'undefined') {
              postCustomization.video_processing = video_processing
            }
          }

          postCustomization.kind = code
          postCustomization.profile_gid = id

          delete postCustomization.view

          const { message = '' } = postCustomization

          const { mentionsFromPost } = mentionsTransformer({
            body: message || postText,
            color: 'rgb(0,0,0)',
            fontWeight: 400,
            agencyMentions: agencyMentionsOptions.data,
            network: code,
          })

          const mentionsForNetwork = []
          mentionsFromPost.forEach((item) => {
            if (item[code]) {
              const { id } = item[code]
              mentionsForNetwork.push({ id, mention: item.name })
            }
          })
          if (mentionsForNetwork.length !== 0) {
            postCustomization.mentions = mentionsForNetwork
          }

          const attachments = collectPostAttachments({
            post: {
              postComponent: customizedPostComponent,
              postImages: customizedPostImages,
              postVideos: customizedPostVideos,
              postDocuments: customizedPostDocuments,
              postMixedMedias: customizedPostMixedMedias,
            },
          })

          if (attachments) {
            postCustomization.attachments = attachments
          }

          const { comments = [] } = postCustomization

          if (comments && comments.length !== 0) {
            const updated_comments = []

            comments.forEach((comment) => {
              const data = { message: comment.message }

              const attachments = collectPostAttachments({
                post: comment,
              })

              if (attachments) {
                data.attachments = attachments
              }

              if (data.message || (data.attachments && data.attachments.length > 0)) {
                updated_comments.push(data)
              }
            })

            if (updated_comments.length !== 0) {
              postCustomization.comments = updated_comments
            }
          }

          data.customizations.push(postCustomization)
        })

        if (workflow && workflow.length !== 0) {
          data.workflow = workflow
        }

        if (data.idea) {
          ideasForSaving.push({ postId: tempId, data })
        } else {
          postsForPublish.push({ postId: tempId, data })
        }
      }
    })

    let allPostsErrors = false
    let allIdeasErrors = false

    setIsUpdatingPosts(true)

    if (postsForPublish.length !== 0) {
      for (const { postId, data } of postsForPublish) {
        const { hasError, publication_id, publication_group_id } = await handleCreatePost(data)
        if (hasError) {
          allPostsErrors = true
        }
        const foundPostIndex = listOfPosts.findIndex(({ tempId }) => tempId === postId)
        if (foundPostIndex > -1) {
          listOfPosts[foundPostIndex].hasPostError = hasError
          if (!hasError) {
            listOfPosts[foundPostIndex].status = APPROVED
            listOfPosts[foundPostIndex].selected = false
          }

          if (publication_id && publication_group_id) {
            listOfPosts[foundPostIndex].id = publication_id
            listOfPosts[foundPostIndex].tempId = publication_id
            listOfPosts[foundPostIndex].publication_group_id = publication_group_id
          }
        }
      }
    }

    if (ideasForSaving.length !== 0) {
      const { selectedEntitiesWithSelectedProfiles } = getSelectedProfileGroupsAndTimezone({
        user,
        selectedEntities,
        selectedProfiles,
      })

      const entity_gids = selectedEntitiesWithSelectedProfiles.map(({ id }) => id)

      for (const { postId, data } of ideasForSaving) {
        const { hasError } = await handleCreateIdea({ data, entity_gids })
        if (hasError) {
          allIdeasErrors = true
        }
        const foundPostIndex = listOfPosts.findIndex(({ tempId }) => tempId === postId)
        if (foundPostIndex > -1) {
          listOfPosts[foundPostIndex].hasPostError = hasError
          if (!hasError) {
            listOfPosts[foundPostIndex].status = APPROVED
            listOfPosts[foundPostIndex].selected = false
          }
        }
      }
    }

    setIsUpdatingPosts(false)
    handleClickReloadCalendar()

    handleClickClosePostSubmitModal()

    if (!allPostsErrors && !allIdeasErrors) {
      if (postsForPublish.length > 0) {
        Alert.success(`All posts have been scheduled.`, { timeout: 5000 })
      }

      if (ideasForSaving.length > 0) {
        Alert.success(ideasForSaving.length === 1 ? 'Idea has been created.' : 'Ideas have been created.', {
          timeout: 5000,
        })
      }

      handleDismiss({ getData: true })
    } else {
      if (allPostsErrors) {
        setListOfPosts([...listOfPosts])

        Alert.error(`Some posts could not be scheduled.`, { timeout: 5000 })
      }

      if (allIdeasErrors) {
        setListOfPosts([...listOfPosts])

        Alert.error(`Some ideas could not have been saved.`, { timeout: 5000 })
      }
    }
  }

  const handleClickCloseSocialProfilesCustomizationsModal = ({ customizationData }) => {
    setCustomizationData({ ...customizationData })
    setIsBulkSocialProfilesCustomizationsModalOpen(null)
  }

  const handleClickOpenMediaLibraryModal = ({ type }) => {
    const postMedias = []

    listOfPosts.forEach((post) => {
      let data = []

      if (type === POST_IMAGE) {
        data = post.postImages
      } else if (type === POST_VIDEO) {
        data = post.postVideos
      } else if (type === POST_DOCUMENT) {
        data = post.postDocuments
      }

      const { 0: firstMedia } = data || []
      if (firstMedia) {
        postMedias.push(firstMedia)
      }
    })

    setMediaLibraryOptions({ ...{ isOpen: true, type, postMedias } })
  }

  const handleClickCloseMediaLibraryModal = () => {
    setMediaLibraryOptions({ ...MEDIA_LIBRARY_MODAL_OPTIONS })
  }

  const handleClickOpenMediaDiscoverModal = ({ type }) => {
    const postMedias = []

    listOfPosts.forEach((post) => {
      const data = type === POST_IMAGE ? post.postImages : post.postVideos

      const { 0: firstMedia } = data || []
      if (firstMedia) {
        postMedias.push(firstMedia)
      }
    })
    setMediaDiscoverOptions({ ...{ isOpen: true, type, postMedias } })
  }

  const handleClickCloseMediaDiscoverModal = () => {
    setMediaDiscoverOptions({ ...MEDIA_DISCOVER_MODAL_OPTIONS })
  }

  const handleClickCloseMediaSettingsModal = () => {
    setMediaSettingsOptions({ ...MEDIA_SETTINGS_MODAL_OPTIONS })
  }

  const handleClickGoogleDrive = ({ type }) => {
    const { access_token } = authResponse || {}

    if (access_token) {
      savedGoogleDocsAuthResponse.access_token = access_token
    }
    savedGoogleDocsAuthResponse.type = type

    setSavedGoogleDocsAuthResponse({ ...savedGoogleDocsAuthResponse })

    let MIME_TYPES = ''
    let VIEW_ID = 'DOCS_IMAGES_AND_VIDEOS'

    if (type === POST_IMAGE) {
      MIME_TYPES = Object.keys(IMAGE_MIME_TYPES)
        .map((mime) => `image/${mime}`)
        .join(',')
    } else if (type === POST_VIDEO) {
      MIME_TYPES = Object.keys(VIDEO_MIME_TYPES)
        .map((mime) => `video/${mime}`)
        .join(',')
    } else if (type === POST_DOCUMENT) {
      MIME_TYPES = Object.keys(DOCUMENT_MIME_TYPES)
        .map((mime) => `application/${mime}`)
        .join(',')
      VIEW_ID = 'DOCS'
    }

    const { config } = googleDrivePickerConfiguration({
      access_token,
      mime_types: MIME_TYPES,
      view_id: VIEW_ID,
      callbackFunction: (data) => {
        const { docs = [] } = data
        setSharedDocsForUpload([...docs])
      },
    })

    openPicker(config)
  }

  const handleClickDropBox = ({ type }) => {
    savedGoogleDocsAuthResponse.access_token = null
    savedGoogleDocsAuthResponse.type = type

    setSavedGoogleDocsAuthResponse({ ...savedGoogleDocsAuthResponse })

    let EXTENSIONS = []

    if (type === POST_IMAGE) {
      EXTENSIONS = IMAGE_EXTENSIONS
    } else if (type === POST_VIDEO) {
      EXTENSIONS = VIDEO_EXTENSIONS
    } else if (type === POST_DOCUMENT) {
      EXTENSIONS = DOCUMENT_EXTENSIONS
    }

    const { config } = dropboxPickerConfiguration({
      extensions: EXTENSIONS,
      callbackFunction: (files) => {
        setSharedDocsForUpload([...files.map(({ link }) => ({ url: link }))])
      },
    })
    Dropbox.choose(config)
  }

  const handleClickOneDrive = ({ type }) => {
    savedGoogleDocsAuthResponse.access_token = null
    savedGoogleDocsAuthResponse.type = type
    setSavedGoogleDocsAuthResponse({ ...savedGoogleDocsAuthResponse })

    let EXTENSIONS = []

    if (type === POST_IMAGE) {
      EXTENSIONS = IMAGE_EXTENSIONS
    } else if (type === POST_VIDEO) {
      EXTENSIONS = VIDEO_EXTENSIONS
    } else if (type === POST_DOCUMENT) {
      EXTENSIONS = DOCUMENT_EXTENSIONS
    }

    const { config } = oneDrivePickerConfiguration({
      extensions: EXTENSIONS.join(','),
      callbackFunction: (files) => {
        if (files && files.value && files.value.length !== 0) {
          setSharedDocsForUpload([...files.value.map((item) => ({ url: item['@microsoft.graph.downloadUrl'] }))])
        }
      },
    })

    OneDrive.open(config)
  }

  const uploadMediaToServerByFormData = async ({ file, uploadingId }) => {
    const { tempId, media, error } = await uploadMediaToServerByFormDataHelper({ file, uploadingId })

    setUploadingMediasAmount(uploadedMediasViaInput - 1)
    const { type, filename, media_gid, codec_name } = media

    let postComponent
    let mediaComponent
    let foundPostIndex = -1

    if (type === IMAGE) {
      postComponent = POST_IMAGE
      mediaComponent = 'postImages'
    } else if (type === VIDEO) {
      postComponent = POST_VIDEO
      mediaComponent = 'postVideos'
    } else if (type === DOCUMENT) {
      postComponent = POST_DOCUMENT
      mediaComponent = 'postDocuments'
    }

    const findPost = () => {
      for (let i = 0; i < listOfPosts.length; i++) {
        if (listOfPosts[i][mediaComponent] && listOfPosts[i][mediaComponent].length) {
          for (let j = 0; j < listOfPosts[i][mediaComponent].length; j++) {
            const { tempId: mediaTempId } = listOfPosts[i][mediaComponent][j]

            if (mediaTempId === Number(tempId)) {
              listOfPosts[i][mediaComponent][j] = {
                ...media,
                id: media_gid,
                isNew: true,
                tempId: null,
              }
              foundPostIndex = i
              break
            }
          }

          if (foundPostIndex > -1) {
            listOfPosts[foundPostIndex] = {
              ...listOfPosts[foundPostIndex],
              selected: true,
              postText: clearFileName(filename),
              autoFilledPostText: true,
              postComponent,
            }
            if (postComponent === POST_IMAGE) {
              listOfPosts[foundPostIndex].postVideos = []
              listOfPosts[foundPostIndex].postDocuments = []
            } else if (postComponent === POST_VIDEO) {
              listOfPosts[foundPostIndex].postImages = []
              listOfPosts[foundPostIndex].postDocuments = []
            } else if (postComponent === POST_DOCUMENT) {
              listOfPosts[foundPostIndex].postImages = []
              listOfPosts[foundPostIndex].postVideos = []
            }
            break
          }
        }
      }
    }

    findPost()

    if (foundPostIndex > -1) {
      if (error) {
        listOfPosts.splice(foundPostIndex, 1)
        Alert.error(error, { timeout: 5000 })
      }
      setListOfPosts([...listOfPosts])
    } else if (codec_name === 'gif') {
      postComponent = POST_VIDEO
      mediaComponent = 'postVideos'
      media.type = VIDEO
      findPost()

      if (foundPostIndex > -1) {
        if (error) {
          listOfPosts.splice(foundPostIndex, 1)
          Alert.error(error, { timeout: 5000 })
        }
        setListOfPosts([...listOfPosts])
      }
    }
  }

  const handlePostImageFileChange = async (event) => {
    if (event.currentTarget.files.length !== 0) {
      const { files } = event.currentTarget
      let extensionError = false
      let sizeError = false

      const newListOfPosts = []
      for (const file of files) {
        const { name, size } = file
        if (IMAGE_EXTENSIONS.includes(name.substring(name.lastIndexOf('.')).toLowerCase())) {
          if (size <= MAX_IMAGE_SIZE) {
            const uploadingId = new Date().getTime() + Math.floor(Math.random() * 1000)
            newListOfPosts.push({
              tempId: `${uploadingId}`,
              selected: false,
              postComponent: POST_IMAGE,
              postImages: [{ tempId: uploadingId, id: uploadingId, isNew: true, file, type: IMAGE }],
              postVideos: [],
              postDocuments: [],
              postMixedMedias: [],
            })
          } else {
            sizeError = true
          }
        } else {
          extensionError = true
        }
      }
      if (extensionError) {
        Alert.error(`Only ${IMAGE_EXTENSIONS.join(', ')} files allowed!`)
      }
      if (sizeError) {
        Alert.error(`The size of the image must be less than ${MAX_IMAGE_SIZE_TEXT}!`)
      }

      if (newListOfPosts.length) {
        setListOfPosts([...listOfPosts, ...newListOfPosts])
      }
    }
  }

  const handlePostVideoFileChange = async (event) => {
    if (event.currentTarget.files.length !== 0) {
      const { files } = event.currentTarget
      let extensionError = false
      let sizeError = false

      const newListOfPosts = []

      for (const file of files) {
        const { name, size } = file
        if (VIDEO_EXTENSIONS.includes(name.substring(name.lastIndexOf('.')).toLowerCase())) {
          if (size <= MAX_VIDEO_SIZE) {
            const uploadingId = new Date().getTime() + Math.floor(Math.random() * 1000)
            newListOfPosts.push({
              tempId: `${uploadingId}`,
              selected: false,
              postComponent: POST_VIDEO,
              postVideos: [{ tempId: uploadingId, id: uploadingId, isNew: true, file, type: VIDEO }],
              postImages: [],
              postDocuments: [],
              postMixedMedias: [],
            })
          } else {
            sizeError = true
          }
        } else {
          extensionError = true
        }
      }
      if (extensionError) {
        Alert.error(`Only ${VIDEO_EXTENSIONS.join(', ')} files allowed!`)
      }
      if (sizeError) {
        Alert.error(`The size of the video must be less than ${MAX_VIDEO_SIZE_TEXT}!`)
      }

      if (newListOfPosts.length) {
        setListOfPosts([...listOfPosts, ...newListOfPosts])
      }
    }
  }

  const handlePostDocumentFileChange = async (event) => {
    if (event.currentTarget.files.length !== 0) {
      const { files } = event.currentTarget
      let extensionError = false
      let sizeError = false

      const newListOfPosts = []

      for (const file of files) {
        const { name, size } = file
        if (DOCUMENT_EXTENSIONS.includes(name.substring(name.lastIndexOf('.')).toLowerCase())) {
          if (size <= MAX_DOCUMENT_SIZE) {
            const uploadingId = new Date().getTime() + Math.floor(Math.random() * 1000)
            newListOfPosts.push({
              tempId: `${uploadingId}`,
              selected: false,
              postComponent: POST_DOCUMENT,
              postDocuments: [{ tempId: uploadingId, id: uploadingId, isNew: true, file, type: DOCUMENT }],
              postImages: [],
              postVideos: [],
              postMixedMedias: [],
            })
          } else {
            sizeError = true
          }
        } else {
          extensionError = true
        }
      }
      if (extensionError) {
        Alert.error(`Only ${DOCUMENT_EXTENSIONS.join(', ')} files allowed!`)
      }
      if (sizeError) {
        Alert.error(`The size of the document must be less than ${MAX_DOCUMENT_SIZE_TEXT}!`)
      }

      if (newListOfPosts.length) {
        setListOfPosts([...listOfPosts, ...newListOfPosts])
      }
    }
  }

  const uploadMediaToServerByStringData = async ({ mediaURL, uploadingId, type }) => {
    let response
    let hasError = false

    try {
      response = await request({
        method: 'POST',
        body: { mediaURL, mediaType: type },
        path: `${ROUTE_MEDIA}?tempId=${uploadingId}`,
      })
      const { error } = response || {}

      if (!response || error) {
        hasError = true
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      }
    } catch (error) {
      hasError = true
      errorHelper({
        error,
        componentName: BulkModal.displayName,
        functionName: 'uploadMediaToServerByStringData',
      })
    } finally {
      const { tempId, ...media } = response || {}
      let postComponent
      let mediaComponent
      let foundPostIndex = -1

      const { media_gid, type } = media || {}

      if (!media_gid) {
        hasError = true
      }

      if (type === IMAGE) {
        postComponent = POST_IMAGE
        mediaComponent = 'postImages'
      } else if (type === VIDEO) {
        postComponent = POST_VIDEO
        mediaComponent = 'postVideos'
      } else if (type === DOCUMENT) {
        postComponent = POST_DOCUMENT
        mediaComponent = 'postDocuments'
      }

      for (let i = 0; i < listOfPosts.length; i++) {
        if (listOfPosts[i][mediaComponent]) {
          for (let j = 0; j < listOfPosts[i][mediaComponent].length; j++) {
            const { tempId: mediaTempId, uploadingId } = listOfPosts[i][mediaComponent][j]
            if ((mediaTempId || uploadingId) === Number(tempId)) {
              listOfPosts[i][mediaComponent][j] = {
                ...media,
                id: media_gid,
                isNew: true,
                tempId: null,
              }
              foundPostIndex = i

              // this is needed for Instagram post because of adding a user_tag to an image when we do not have the media_gid of it (hashtag search, user search)
              if (!hasError) {
                selectedProfiles.forEach(({ code, id }) => {
                  if (
                    code === INSTAGRAM &&
                    listOfPosts[i].customizations &&
                    listOfPosts[i].customizations[code] &&
                    listOfPosts[i].customizations[code][id] &&
                    listOfPosts[i].customizations[code][id].user_tags
                  ) {
                    listOfPosts[i].customizations[code][id].user_tags.forEach((tag) => {
                      if (!tag.media_gid) {
                        tag.media_gid = media_gid
                      }
                    })
                  }
                })
              }

              break
            }
          }

          if (foundPostIndex > -1) {
            listOfPosts[foundPostIndex] = {
              ...listOfPosts[foundPostIndex],
              selected: true,
              autoFilledPostText: true,
              postComponent,
            }
            if (postComponent === POST_IMAGE) {
              listOfPosts[foundPostIndex].postVideos = []
              listOfPosts[foundPostIndex].postDocuments = []
            } else if (postComponent === POST_VIDEO) {
              listOfPosts[foundPostIndex].postImages = []
              listOfPosts[foundPostIndex].postDocuments = []
            } else if (postComponent === POST_DOCUMENT) {
              listOfPosts[foundPostIndex].postImages = []
              listOfPosts[foundPostIndex].postVideos = []
            }
            break
          }
        } else if (listOfPosts[i].tempId && tempId === listOfPosts[i].tempId) {
          foundPostIndex = i
        }
      }

      if (foundPostIndex > -1) {
        if (hasError) {
          listOfPosts.splice(foundPostIndex, 1)
        }
        setListOfPosts([...listOfPosts])
      }
    }
  }

  const handleClickAddMedias = async ({ selectedMedias, postComponent }) => {
    const newListOfPosts = []
    const listOfPostsByPostComponent = []

    listOfPosts.forEach((item) => {
      if (item.postComponent === postComponent) {
        listOfPostsByPostComponent.push(item)
      } else {
        newListOfPosts.push(item)
      }
    })

    let postData = 'postImages'
    let mediaType = IMAGE

    if (postComponent === POST_VIDEO) {
      postData = 'postVideos'
      mediaType = VIDEO
    } else if (postComponent === POST_DOCUMENT) {
      postData = 'postDocuments'
      mediaType = DOCUMENT
    }

    const medias_for_dehibernation = []

    selectedMedias.forEach((item) => {
      const { id, url, hibernated } = item
      let newPostData = null
      if (id) {
        if (mediaType === VIDEO && hibernated) {
          medias_for_dehibernation.push(item)
        }

        const foundPost = listOfPostsByPostComponent.find(({ [postData]: { 0: item = {} } = [] }) => item.id === id)

        item.hibernated = false

        if (!foundPost) {
          if (!item.dynamic) {
            item.type = mediaType
          }

          newPostData = {
            tempId: Number(
              `${Math.floor(Math.random() * 100)}${Math.floor(Math.random() * 100)}${Math.floor(Math.random() * 100)}`
            ),
            selected: true,
            postText: clearFileName(item.filename),
            autoFilledPostText: !!item.filename,
            postComponent,
            postImages: [],
            postVideos: [],
            postDocuments: [],
            postMixedMedias: [],
            [postData]: [item],
          }
        } else {
          newPostData = foundPost
        }
      } else {
        const uploadingId = Number(
          `${Math.floor(Math.random() * 100)}${Math.floor(Math.random() * 100)}${Math.floor(Math.random() * 100)}`
        )
        newPostData = {
          tempId: `${uploadingId}`,
          selected: false,
          postComponent,
          [postData]: [{ tempId: uploadingId, url, id: uploadingId, isNew: true, type: mediaType }],
        }
      }

      if (postData === 'postImages') {
        newPostData.postVideos = []
        newPostData.postDocuments = []
      } else if (postData === 'postVideos') {
        newPostData.postImages = []
        newPostData.postDocuments = []
      } else if (postData === 'postDocuments') {
        newPostData.postImages = []
        newPostData.postVideos = []
      }

      if (newPostData) {
        newListOfPosts.push(newPostData)
      }
    })

    setListOfPosts([...newListOfPosts])

    if (medias_for_dehibernation.length !== 0) {
      deHibernateMedias({ medias: medias_for_dehibernation })
    }

    if (mediaDiscoverOptions.isOpen) {
      handleClickCloseMediaDiscoverModal()
    } else if (mediaLibraryOptions.isOpen) {
      handleClickCloseMediaLibraryModal()
    }
  }

  const handleUpdateUploadedMedias = ({ medias }) => {
    const keys = [
      { key: 'postImages', postComponent: POST_IMAGE },
      { key: 'postVideos', postComponent: POST_VIDEO },
      { key: 'postDocuments', postComponent: POST_DOCUMENT },
      { key: 'postMixedMedias', postComponent: POST_MIXED_MEDIA },
    ]

    medias.forEach((media) => {
      for (let i = 0; i < listOfPosts.length; i++) {
        for (let j = 0; j < keys.length; j++) {
          const { key, postComponent } = keys[j]

          const foundMediaIndex = listOfPosts[i][key]
            ? listOfPosts[i][key].findIndex(({ id }) => id === media.media_gid)
            : -1

          if (foundMediaIndex > -1) {
            delete media.isNew
            listOfPosts[i][key][foundMediaIndex] = media

            listOfPosts[i].postComponent = postComponent

            keys.forEach((key_item) => {
              if (key_item.key !== key) {
                listOfPosts[i][key_item.key] = []
              }
            })

            break
          }
        }
      }
    })

    setListOfPosts([...listOfPosts])
    setUploadingMediasAmount(0)

    if (isMediaUploadDummyModalOpen) {
      setIsMediaUploadDummyModalOpen(null)
      // TODO:: maybe here I need to send the full array
      // eslint-disable-next-line no-use-before-define
      handleClickSubmitPosts({})
    }
  }

  const checkIfMediaCanBeAttached = ({ func, showAlert = true }) => {
    let foundUploadingMedia = listOfPosts.find(({ postImages = [] }) => postImages.find(({ isNew }) => isNew))

    if (!foundUploadingMedia) {
      foundUploadingMedia = listOfPosts.find(({ postVideos = [] }) => postVideos.find(({ isNew }) => isNew))
    }

    if (!foundUploadingMedia) {
      foundUploadingMedia = listOfPosts.find(({ postDocuments = [] }) => postDocuments.find(({ isNew }) => isNew))
    }

    if (foundUploadingMedia) {
      if (showAlert) {
        Alert.warning(`Your media is still uploading. Please wait just a bit longer before continuing.`, {
          timeout: 5000,
        })
      }
      return null
    } else {
      return func()
    }
  }

  const handleCheckPostForError = (post, customizationData) => {
    post.hasPostError = false
    post.subredditProblem = false
    post.boardProblem = false
    post.mediaUploadingError = false
    post.mediaNeedsUpload = false

    if (post.selected) {
      const {
        postComponent,
        postImages = [],
        postVideos = [],
        postDocuments = [],
        postMixedMedias = [],
        link,
        customizations = {},
        publish_at,
      } = post

      if ((!publish_at && whenToPublish === IDEA) || publish_at === IDEA) {
        post.idea = true
        if (!post.postText && postImages.length === 0 && postVideos.length === 0 && postDocuments.length === 0) {
          post.hasPostError = true
        }
      } else {
        const { picture_url = '', url = '' } = link || {}

        const { video_processing = user_settings_video_processing } = customizations || {}
        const { image_processing = user_settings_image_processing } = customizations || {}

        selectedProfiles.forEach((profile) => {
          const { code, id } = profile

          const profileType = getProfileFlavor({ profile })

          const isTwitterProfileFreePlan = profileType === PROFILE_TYPE_TWITTER_FREE

          const networkLimit = NETWORK_LIMITS.find((limit) => limit.code === code)

          if (networkLimit) {
            const {
              titleLimit,
              postTextMaxLimit,
              postTextMaxLimitPaid,
              commentTextMaxLimit,
              commentTextMaxLimitPaid,
              maxHashTags,
              minReelAspectRatio,
              maxReelAspectRatio,
              minReelAspectRatioVideoProcessingOff,
              maxReelAspectRatioVideoProcessingOff,
              videoTitleLimit,
              maxVideoDuration,
              minVideoDuration,
              minImageWidth,
              minImageHeight,
              maxImageWidth,
              maxImageHeight,
              maxVideoSize,
              imageExtensions,
              videoExtensions,
              maxImages,
              maxImageSize,
              minImages,
              maxVideos,
              maxTagsLength,
              maxDocumentSize,
              documentExtensions,
              maxDocuments = 0,
              slugMaxLimit,
              maxTagsLimit,
              minMixedMediasInCarousel,
            } = networkLimit

            let { minVideoAspectRatio, maxVideoAspectRatio, minImageAspectRatio, maxImageAspectRatio } = networkLimit

            const { [code]: networkCustomizations } = post.customizations || {}
            const { [id]: profileCustomizations } = networkCustomizations || {}
            let {
              postImages: customizedPostImages = postImages,
              postVideos: customizedPostVideos = postVideos,
              postDocuments: customizedPostDocuments = postDocuments,
              postMixedMedias: customizedPostMixedMedias = postMixedMedias,
              publishing_option,
              device_gid,
            } = profileCustomizations || {}

            let customizedPostComponent = profileCustomizations.postComponent

            if (typeof customizedPostComponent === 'undefined') {
              customizedPostComponent = postComponent
            }

            minImageAspectRatio = minImageAspectRatio
              ? Number(
                  minImageAspectRatio
                    .split(':')
                    .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                    .toFixed(2)
                )
              : null

            maxImageAspectRatio = maxImageAspectRatio
              ? Number(
                  maxImageAspectRatio
                    .split(':')
                    .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                    .toFixed(2)
                )
              : null

            minVideoAspectRatio = minVideoAspectRatio
              ? Number(
                  minVideoAspectRatio
                    .split(':')
                    .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                    .toFixed(2)
                )
              : null

            maxVideoAspectRatio = maxVideoAspectRatio
              ? Number(
                  maxVideoAspectRatio
                    .split(':')
                    .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                    .toFixed(2)
                )
              : null

            let postCustomization = {}
            if (post.customizations && post.customizations[code] && post.customizations[code][id]) {
              postCustomization = post.customizations[code][id]
            } else if (post.defaultCustomizations && post.defaultCustomizations[code]) {
              postCustomization = post.defaultCustomizations[code]
            }

            const { comments = [], message = '', media_type, tags = [] } = postCustomization || {}

            if (customizedPostComponent === POST_MIXED_MEDIA) {
              customizedPostImages = []
              customizedPostVideos = []

              if (code === INSTAGRAM || code === THREADS) {
                if (code === INSTAGRAM) {
                  if (customizedPostMixedMedias.length === 1) {
                    const { 0: { type } = {} } = customizedPostMixedMedias

                    if (type === IMAGE || type === IMAGE_DYNAMIC) {
                      customizedPostComponent = POST_IMAGE
                    } else if (type === VIDEO || type === VIDEO_DYNAMIC) {
                      customizedPostComponent = POST_VIDEO
                    }
                  }
                }

                customizedPostMixedMedias.forEach((media) => {
                  if (media.type) {
                    if (media.type.includes(IMAGE)) {
                      customizedPostImages.push(media)
                    }
                    if (media.type.includes(VIDEO)) {
                      customizedPostVideos.push(media)
                    }
                  }
                })
              } else if (code === FACEBOOK || code === LINKEDIN || code === PINTEREST || code === REDDIT) {
                if (customizedPostMixedMedias[0] && customizedPostMixedMedias[0].type) {
                  if (customizedPostMixedMedias[0].type.includes(IMAGE)) {
                    customizedPostImages.push(customizedPostMixedMedias[0])
                  } else if (customizedPostMixedMedias[0].type.includes(VIDEO)) {
                    customizedPostVideos.push(customizedPostMixedMedias[0])
                  }
                }
              } else if (code === SNAPCHAT || code === YOUTUBE || code === VIMEO) {
                const foundMedia = customizedPostMixedMedias.find(({ type }) => type.includes(VIDEO))
                if (foundMedia) {
                  customizedPostVideos.push(foundMedia)
                }
              } else if (code === VISTAPAGE || code === GOOGLE) {
                const foundMedia = customizedPostMixedMedias.find(({ type }) => type.includes(IMAGE))
                if (foundMedia) {
                  customizedPostImages.push(foundMedia)
                }
              } else if (code === TIKTOK || code === TWITTER || code === TUMBLR) {
                if (customizedPostMixedMedias[0] && customizedPostMixedMedias[0].type) {
                  if (customizedPostMixedMedias[0].type.includes(IMAGE)) {
                    customizedPostImages = customizedPostMixedMedias.filter(({ type }) => type && type.includes(IMAGE))
                  } else if (customizedPostMixedMedias[0].type.includes(VIDEO)) {
                    customizedPostVideos.push(customizedPostMixedMedias[0])
                  }
                }
              }
            }

            if (
              code === INSTAGRAM &&
              (media_type === PUBLISH_AS_REELS ||
                (customizedPostComponent === POST_MIXED_MEDIA && media_type === PUBLISH_AS_CAROUSEL) ||
                (!media_type && customizedPostComponent === POST_VIDEO && customizedPostVideos.length === 1))
            ) {
              if (minReelAspectRatio && video_processing) {
                minVideoAspectRatio = Number(
                  minReelAspectRatio
                    .split(':')
                    .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                    .toFixed(2)
                )
              } else if (minReelAspectRatioVideoProcessingOff && !video_processing) {
                minVideoAspectRatio = Number(
                  minReelAspectRatioVideoProcessingOff
                    .split(':')
                    .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                    .toFixed(2)
                )
              }

              if (maxReelAspectRatio && video_processing) {
                maxVideoAspectRatio = Number(
                  maxReelAspectRatio
                    .split(':')
                    .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                    .toFixed(2)
                )
              } else if (maxReelAspectRatioVideoProcessingOff && !video_processing) {
                maxVideoAspectRatio = Number(
                  maxReelAspectRatioVideoProcessingOff
                    .split(':')
                    .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                    .toFixed(2)
                )
              }
            }

            let commentTextLengthLimit = false
            let hashtagsTextLengthLimit = false
            let postMediaLengthLimit = false
            let postMediaAspectRatioLimit = false
            let postVideoTitleLengthLimit = false
            let postVideoDurationLimit = false
            let postMediaResolutionLimit = false
            let postVideoExtensionError = false
            let postVideoSizeLimit = false
            let postImageSizeLimit = false
            let postImageExtensionError = false
            let noContent = false
            let postLinkPictureUrlLengthLimit = false
            let postTextMinLimit = false
            let tagsTextMaxLimit = false
            let notFoundVariables = false
            let postDocumentExtensionError = false
            let postDocumentSizeLimit = false
            const postDocumentTitleLengthLimit = false
            const postDocumentDescriptionLengthLimit = false
            let documentsNotSupported = false
            let hashtagsCommentLengthLimit = false
            let postPinLinkError = false
            let titleLengthLimit = false
            let slugLengthLimit = false
            let tagsMaxLimit = false
            let deviceLengthLimit = false
            let postMixedMediasLengthLimit = false

            let postText = message || post.postText || ''

            if (importFrom === IMPORT_FROM_CSV || importFrom === IMPORT_FROM_RSS) {
              const { variableTransformedText, notFoundVariables: notFoundVariablesArray } = variableTransformer({
                str: postText,
                savedVariables: savedVariables.list,
                post: { profile_variables: savedVariables.profile_variables },
                profileId: id,
              })

              postText = variableTransformedText

              if (notFoundVariablesArray.length > 0) {
                notFoundVariables = true
              }
            }

            const updatedPostText = mentionsTransformer({
              body: postText,
              color: COLOR_CONSTANTS.BLACK,
              fontWeight: 400,
              agencyMentions: agencyMentionsOptions.data,
              network: code,
            }).updatedTextTransformedMentions

            let symbolsAllowedLength = postTextMaxLimit - updatedPostText.length
            if (code === TWITTER) {
              const updatedPostTextLength = twitter.getTweetLength(updatedPostText)

              symbolsAllowedLength =
                (isTwitterProfileFreePlan ? postTextMaxLimit : postTextMaxLimitPaid) - updatedPostTextLength
            } else if (code === PINTEREST) {
              symbolsAllowedLength =
                postTextMaxLimit -
                updatedPostText
                  .split('\n')
                  .map((line) => {
                    return line
                      .split(' ')
                      .filter((word) => !word.match(URL_REGEXP))
                      .join(' ')
                  })
                  .join('\n').length
            }

            for (let i = 0; i < comments.length; i++) {
              let hasCommentMessageTextLengthError = false

              if (code === TWITTER && !isTwitterProfileFreePlan) {
                if (comments[i].message.length > commentTextMaxLimitPaid) {
                  hasCommentMessageTextLengthError = true
                }
              } else if (comments[i].message.length > commentTextMaxLimit) {
                hasCommentMessageTextLengthError = true
              }

              if (hasCommentMessageTextLengthError) {
                commentTextLengthLimit = i + 1
                break
              } else if (!notFoundVariables) {
                const { notFoundVariables: notFoundVariablesTemp } = variableTransformer({
                  str: comments[i].message,
                  savedVariables,
                  post,
                  profileId: id,
                })

                if (notFoundVariablesTemp.length > 0) {
                  notFoundVariables = true
                  break
                } else if (code === INSTAGRAM) {
                  const hashtagsComment = twitter.extractHashtags(comments[i].message)

                  if (hashtagsComment.length > maxHashTags) {
                    hashtagsCommentLengthLimit = i + 1
                    break
                  }
                }
              }
            }

            if (
              (customizedPostComponent === POST_VIDEO || customizedPostComponent === POST_MIXED_MEDIA) &&
              customizedPostVideos.length !== 0
            ) {
              const limitedPostVideos = customizedPostVideos.slice(0, maxVideos)
              for (const video of limitedPostVideos) {
                const {
                  id,
                  isNew,
                  aspect_ratio,
                  duration,
                  hasAspectRatioError,
                  hasDurationError,
                  extension,
                  size,
                } = video

                if (!id) {
                  post.mediaNeedsUpload = true
                } else if (!isNew) {
                  let updatedAspectRatio = aspect_ratio ? Number(aspect_ratio).toFixed(2) : 0
                  let updatedDuration = duration

                  if (!updatedAspectRatio || !updatedDuration) {
                    const element = document.getElementById(`${POST_MEDIA_CLASS_NAME}-${id}`)
                    const { children: { 0: videoElement } = {} } = element
                    const { videoHeight, videoWidth, duration: videoDuration } = videoElement
                    if (videoWidth && videoHeight) {
                      updatedAspectRatio = Number((videoWidth / videoHeight).toFixed(2))
                      video.aspect_ratio = updatedAspectRatio
                    }
                    if (videoDuration) {
                      updatedDuration = videoDuration
                      video.duration = videoDuration
                    }
                  }

                  if (minVideoAspectRatio && maxVideoAspectRatio) {
                    if (typeof hasAspectRatioError === 'boolean' && hasAspectRatioError) {
                      if (code !== INSTAGRAM || (code === INSTAGRAM && !video_processing)) {
                        postMediaAspectRatioLimit = id
                      }
                    } else if (typeof hasAspectRatioError !== 'boolean') {
                      video.hasAspectRatioError = false

                      if (updatedAspectRatio) {
                        if (updatedAspectRatio < minVideoAspectRatio || updatedAspectRatio > maxVideoAspectRatio) {
                          if (code !== INSTAGRAM || (code === INSTAGRAM && !video_processing)) {
                            postMediaAspectRatioLimit = id
                          }

                          video.hasAspectRatioError = true
                        }
                      } else {
                        delete video.hasAspectRatioError
                      }
                    }
                  }

                  if (minVideoDuration && maxVideoDuration) {
                    if (typeof hasDurationError === 'boolean' && hasDurationError) {
                      postVideoDurationLimit = id
                    } else if (typeof hasDurationError !== 'boolean') {
                      video.hasDurationError = false

                      if (updatedDuration) {
                        if (updatedDuration < minVideoDuration || updatedDuration > maxVideoDuration) {
                          postVideoDurationLimit = id
                          video.hasDurationError = true
                        }
                      } else {
                        delete video.hasDurationError
                      }
                    }
                  }

                  video.hasExtensionError = false
                  if (videoExtensions && extension && !videoExtensions.includes(`.${extension}`)) {
                    postVideoExtensionError = id
                    video.hasExtensionError = true
                  }

                  video.hasSizeError = false
                  if (maxVideoSize && size > maxVideoSize) {
                    postVideoSizeLimit = id
                    video.hasSizeError = true
                  }
                } else {
                  post.mediaUploadingError = true
                }
              }
            }

            if (
              (customizedPostComponent === POST_IMAGE || customizedPostComponent === POST_MIXED_MEDIA) &&
              customizedPostImages.length !== 0
            ) {
              const limitedPostImages = customizedPostImages.slice(0, maxImages)
              for (const image of limitedPostImages) {
                const {
                  id,
                  isNew,
                  aspect_ratio,
                  width,
                  height,
                  size,
                  extension,
                  hasAspectRatioError,
                  hasResolutionError,
                } = image
                if (!id) {
                  post.mediaNeedsUpload = true
                } else if (!isNew) {
                  let updatedAspectRatio = aspect_ratio ? Number(aspect_ratio).toFixed(2) : 0
                  let updatedWidth = width
                  let updatedHeight = height

                  if (!updatedAspectRatio || !updatedWidth || !updatedHeight) {
                    const { naturalWidth, naturalHeight } = document.getElementById(`${POST_MEDIA_CLASS_NAME}-${id}`)
                    if (naturalWidth && naturalHeight) {
                      updatedAspectRatio = Number((naturalWidth / naturalHeight).toFixed(2))
                      image.aspect_ratio = updatedAspectRatio
                      updatedWidth = naturalWidth
                      image.width = naturalWidth
                      updatedHeight = naturalHeight
                      image.height = naturalHeight
                    }
                  }

                  if (minImageAspectRatio && maxImageAspectRatio) {
                    if (typeof hasAspectRatioError === 'boolean' && image.hasAspectRatioError) {
                      postMediaAspectRatioLimit = id
                    } else if (typeof hasAspectRatioError !== 'boolean') {
                      image.hasAspectRatioError = false

                      if (updatedAspectRatio) {
                        if (updatedAspectRatio < minImageAspectRatio || updatedAspectRatio > maxImageAspectRatio) {
                          postMediaAspectRatioLimit = id
                          image.hasAspectRatioError = true
                        }
                      } else {
                        delete image.hasAspectRatioError
                      }
                    }
                  }

                  if ((minImageHeight && minImageWidth) || (maxImageHeight && maxImageWidth)) {
                    if (typeof hasResolutionError === 'boolean' && image.hasResolutionError) {
                      postMediaResolutionLimit = id
                    } else if (typeof hasResolutionError !== 'boolean') {
                      image.hasResolutionError = false

                      if (updatedWidth && updatedHeight) {
                        if (minImageWidth && minImageHeight) {
                          if (updatedWidth < minImageWidth || updatedHeight < minImageHeight) {
                            postMediaResolutionLimit = id
                            image.hasResolutionError = true
                          }
                        }

                        if (maxImageWidth && maxImageHeight) {
                          if (updatedWidth > maxImageWidth || updatedHeight > maxImageHeight) {
                            if (!image_processing) {
                              postMediaResolutionLimit = id
                              image.hasResolutionError = true
                            }
                          }
                        }
                      } else {
                        delete image.hasResolutionError
                      }
                    }
                  }

                  image.hasExtensionError = false
                  if (imageExtensions && !image_processing && extension && !imageExtensions.includes(`.${extension}`)) {
                    postImageExtensionError = id
                    image.hasExtensionError = true
                  }

                  image.hasSizeError = false
                  if (maxImageSize && size > maxImageSize && !image_processing) {
                    postImageSizeLimit = id
                    image.hasSizeError = true
                  }
                } else {
                  post.mediaUploadingError = true
                }
              }
            }

            if (customizedPostComponent === POST_DOCUMENT && customizedPostDocuments.length !== 0) {
              if (code === LINKEDIN) {
                const limitedPostDocuments = customizedPostDocuments.slice(0, maxDocuments)
                for (const document of limitedPostDocuments) {
                  const { id, isNew, size, extension, title, description } = document
                  document[code] = {}

                  if (!isNew) {
                    document[code].hasExtensionError = false
                    if (documentExtensions && extension && !documentExtensions.includes(`.${extension}`)) {
                      postDocumentExtensionError = id
                      document[code].hasExtensionError = true
                    }

                    document[code].hasSizeError = false
                    if (maxDocumentSize && size > maxDocumentSize) {
                      postDocumentSizeLimit = id
                      document[code].hasSizeError = true
                    }

                    document[code].hasTitleLengthLimitError = false
                    // if (!title) {
                    //   postDocumentTitleLengthLimit = id
                    //   document[code].hasTitleLengthLimitError = true
                    // }

                    document[code].hasDescriptionLengthLimitError = false
                    // if (!description) {
                    //   postDocumentDescriptionLengthLimit = id
                    //   document[code].hasDescriptionLengthLimitError = true
                    // }
                  }
                }
              } else {
                documentsNotSupported = true
              }
            }

            if (code === FACEBOOK || code === TWITTER || code === THREADS) {
              if (
                updatedPostText.length === 0 &&
                (!customizedPostComponent ||
                  (customizedPostComponent === POST_IMAGE && customizedPostImages.length === 0) ||
                  (customizedPostComponent === POST_VIDEO && customizedPostVideos.length === 0) ||
                  (customizedPostComponent === POST_LINK && picture_url.length === 0) ||
                  (customizedPostComponent === POST_MIXED_MEDIA && customizedPostMixedMedias.length === 0))
              ) {
                if (customizedPostComponent === POST_LINK && picture_url.length === 0) {
                  postLinkPictureUrlLengthLimit = true
                } else {
                  noContent = true
                }
              }
            }

            if (code === LINKEDIN) {
              if (
                updatedPostText.length === 0 &&
                (!customizedPostComponent ||
                  (customizedPostComponent === POST_IMAGE && customizedPostImages.length === 0) ||
                  (customizedPostComponent === POST_VIDEO && customizedPostVideos.length === 0) ||
                  (customizedPostComponent === POST_LINK && picture_url.length === 0) ||
                  (customizedPostComponent === POST_DOCUMENT && customizedPostDocuments.length === 0) ||
                  (customizedPostComponent === POST_MIXED_MEDIA && customizedPostMixedMedias.length === 0))
              ) {
                if (customizedPostComponent === POST_LINK && picture_url.length === 0) {
                  postLinkPictureUrlLengthLimit = true
                } else {
                  noContent = true
                }
              }
            }

            if (code === INSTAGRAM) {
              const hashtagsText = twitter.extractHashtags(updatedPostText)

              if (hashtagsText.length > maxHashTags) {
                hashtagsTextLengthLimit = true
              }
              if (customizedPostComponent === POST_IMAGE) {
                if (customizedPostImages.length === 0) {
                  postMediaLengthLimit = true
                }
              } else if (customizedPostComponent === POST_VIDEO) {
                if (customizedPostVideos.length === 0) {
                  postMediaLengthLimit = true
                }
              } else if (customizedPostComponent === POST_MIXED_MEDIA) {
                if (customizedPostMixedMedias.length < minMixedMediasInCarousel) {
                  postMixedMediasLengthLimit = true
                }
              } else if (![POST_VIDEO, POST_IMAGE, POST_LINK, POST_MIXED_MEDIA].includes(customizedPostComponent)) {
                noContent = true
              }
              // this is removed because we are not parsing links on the server
              // else if (customizedPostComponent === POST_LINK && picture_url.length === 0) {
              //   postLinkPictureUrlLengthLimit = true
              // }
            }

            let board_id_customized = ''
            let board_id_name_customized = ''
            let section_id_customized = ''
            let section_id_name_customized = ''
            let subreddit_customized = ''

            if (customizationData[code] && customizationData[code][id]) {
              ;({
                board_id: board_id_customized,
                board_id_name: board_id_name_customized,
                section_id: section_id_customized,
                section_id_name: section_id_name_customized,
                subreddit: subreddit_customized,
              } = customizationData[code][id])
            }

            if (!post.customizations) {
              post.customizations = {
                [code]: {},
              }
            }
            if (!post.customizations[code]) {
              post.customizations[code] = {}
            }

            if (!post.customizations[code][id]) {
              post.customizations[code][id] = postCustomization
            }

            if (code === REDDIT) {
              if (updatedPostText.length === 0) {
                postTextMinLimit = true
              }

              const { subreddit = subreddit_customized } = postCustomization
              if (subreddit.length === 0 || subreddit.length > titleLimit) {
                post.subredditProblem = true
              } else if (!postCustomization.subreddit) {
                post.customizations[code][id].subreddit = subreddit_customized
              }
            }

            if (code === PINTEREST) {
              const {
                board_id = board_id_customized,
                board_id_name = board_id_name_customized,
                section_id = section_id_customized,
                section_id_name = section_id_name_customized,
                link: link_customization,
              } = postCustomization

              if (!board_id) {
                post.boardProblem = true
              } else if (!postCustomization.board_id) {
                post.customizations[code][id].board_id = board_id_customized
                post.customizations[code][id].board_id_name = board_id_name || board_id_name_customized
              }

              if (post.customizations[code][id].board_id && !postCustomization.section_id) {
                post.customizations[code][id].section_id = section_id_customized
                post.customizations[code][id].section_id_name = section_id_name || section_id_name_customized || ''
              }

              let link_customization_updated = link_customization

              if (
                !link_customization_updated &&
                customizedPostComponent === POST_LINK &&
                url &&
                typeof link_customization !== 'string'
              ) {
                link_customization_updated = url
              }

              if (link_customization_updated) {
                try {
                  if (link_customization_updated.indexOf('http') !== 0) {
                    link_customization_updated = `https://${link_customization_updated}`
                  }

                  const link_customization_updated_res = link_customization_updated.match(URL_REGEXP)

                  if (!link_customization_updated_res) {
                    postPinLinkError = true
                  }
                } catch (e) {
                  postPinLinkError = true
                }
              }

              if (customizedPostComponent === POST_IMAGE) {
                if (customizedPostImages.length === 0) {
                  postMediaLengthLimit = true
                }
              } else if (customizedPostComponent === POST_VIDEO) {
                if (customizedPostVideos.length === 0) {
                  postMediaLengthLimit = true
                }
              } else if (customizedPostComponent === POST_MIXED_MEDIA) {
                if (customizedPostMixedMedias.length === 0) {
                  postMixedMediasLengthLimit = true
                }
              } else if (![POST_IMAGE, POST_VIDEO, POST_LINK, POST_MIXED_MEDIA].includes(customizedPostComponent)) {
                noContent = true
              }
            }

            if (code === GOOGLE) {
              if (
                updatedPostText.length === 0 &&
                (!customizedPostComponent ||
                  (customizedPostComponent === POST_IMAGE && customizedPostImages.length === 0) ||
                  (customizedPostComponent === POST_LINK && picture_url.length === 0) ||
                  (customizedPostComponent === POST_MIXED_MEDIA && customizedPostImages.length === 0))
              ) {
                if (customizedPostComponent === POST_LINK && picture_url.length === 0) {
                  postLinkPictureUrlLengthLimit = true
                } else {
                  noContent = true
                }
              }
            }

            if (importFrom !== IMPORT_FROM_SEARCH_CONTENT) {
              if (code === TIKTOK) {
                if (customizedPostComponent === POST_IMAGE) {
                  if (customizedPostImages.length < minImages) {
                    postMediaLengthLimit = true
                  } else if (publishing_option === PUBLISHING_OPTION_NOTIFICATION && !device_gid) {
                    deviceLengthLimit = true
                  }
                } else if (customizedPostComponent === POST_VIDEO) {
                  if (customizedPostVideos.length === 0) {
                    postMediaLengthLimit = true
                  }
                } else if (customizedPostComponent === POST_MIXED_MEDIA) {
                  if (customizedPostImages.length === 0 && customizedPostVideos.length === 0) {
                    postMediaLengthLimit = true
                  } else if (customizedPostImages.length) {
                    if (customizedPostImages.length < minImages) {
                      postMediaLengthLimit = true
                    } else if (publishing_option === PUBLISHING_OPTION_NOTIFICATION && !device_gid) {
                      deviceLengthLimit = true
                    }
                  }
                }
              }

              if (code === YOUTUBE || code === VIMEO) {
                const { video_title = '' } = postCustomization
                if (video_title.length === 0) {
                  postVideoTitleLengthLimit = true
                } else if (video_title.length > videoTitleLimit) {
                  postVideoTitleLengthLimit = true
                }
                if (
                  (customizedPostComponent !== POST_VIDEO && customizedPostComponent !== POST_MIXED_MEDIA) ||
                  customizedPostVideos.length === 0
                ) {
                  postMediaLengthLimit = true
                }

                if (code === YOUTUBE) {
                  let tagsLength = 0
                  tags.forEach((tag) => {
                    if (tag.includes(' ')) {
                      tagsLength += 2
                    }
                    tagsLength += tag.length
                  })
                  if (tagsLength > maxTagsLength) {
                    tagsTextMaxLimit = true
                  }
                }
              }

              if (code === SNAPCHAT) {
                if (updatedPostText.length === 0) {
                  postTextMinLimit = true
                }

                if (
                  (customizedPostComponent !== POST_VIDEO && customizedPostComponent !== POST_MIXED_MEDIA) ||
                  customizedPostVideos.length === 0
                ) {
                  postMediaLengthLimit = true
                }
              }
            }

            if (code === VISTAPAGE) {
              if (
                updatedPostText.length === 0 &&
                (!customizedPostComponent ||
                  (customizedPostComponent === POST_IMAGE && customizedPostImages.length === 0) ||
                  (customizedPostComponent === POST_LINK && picture_url.length === 0) ||
                  (customizedPostComponent === POST_MIXED_MEDIA && customizedPostImages.length === 0))
              ) {
                if (customizedPostComponent === POST_LINK && picture_url.length === 0) {
                  postLinkPictureUrlLengthLimit = true
                } else {
                  noContent = true
                }
              }
            }

            if (code === TUMBLR) {
              if (
                updatedPostText.length === 0 &&
                (!customizedPostComponent ||
                  (customizedPostComponent === POST_IMAGE && customizedPostImages.length === 0) ||
                  (customizedPostComponent === POST_VIDEO && customizedPostVideos.length === 0) ||
                  (customizedPostComponent === POST_LINK && url.length === 0) ||
                  (customizedPostComponent === POST_MIXED_MEDIA && customizedPostMixedMedias.length === 0))
              ) {
                noContent = true
              }

              const { title = '', slug = '', tags = [] } = postCustomization

              if (title.length > titleLimit) {
                titleLengthLimit = true
              }

              if (slug.length > slugMaxLimit) {
                slugLengthLimit = true
              }

              if (tags.length > maxTagsLimit) {
                tagsMaxLimit = true
              }
            }

            if (
              symbolsAllowedLength < 0 ||
              commentTextLengthLimit ||
              hashtagsTextLengthLimit ||
              postMediaLengthLimit ||
              postMediaAspectRatioLimit ||
              postVideoTitleLengthLimit ||
              postVideoDurationLimit ||
              postMediaResolutionLimit ||
              postVideoSizeLimit ||
              postVideoExtensionError ||
              postImageSizeLimit ||
              postImageExtensionError ||
              noContent ||
              postLinkPictureUrlLengthLimit ||
              postTextMinLimit ||
              tagsTextMaxLimit ||
              notFoundVariables ||
              postDocumentExtensionError ||
              postDocumentSizeLimit ||
              postDocumentTitleLengthLimit ||
              postDocumentDescriptionLengthLimit ||
              documentsNotSupported ||
              hashtagsCommentLengthLimit ||
              postPinLinkError ||
              titleLengthLimit ||
              slugLengthLimit ||
              tagsMaxLimit ||
              deviceLengthLimit ||
              postMixedMediasLengthLimit
            ) {
              post.hasPostError = true
            }
          }
        })
      }
    }

    return { post }
  }

  const handleClickSubmitPosts = ({ data }) => {
    const updatedCustomizationData = data || customizationData

    const selectedPosts = listOfPosts.filter(({ selected }) => selected)
    if (selectedPosts.length === 0) {
      Alert.error(`Please select at least one post.`)
    } else if (selectedProfiles.length === 0) {
      Alert.error(`Please select at least one social profile.`)
    } else {
      let socialNetworksWithProblems = {}

      let postHasErrorsId = null
      let mediaUploadingError = false
      let mediaNeedsUpload = false

      const customizedDataTemp = { ...customizationData }

      let numberOfPosts = 0
      let numberOfIdeas = 0

      listOfPosts.forEach((item) => {
        selectedProfiles.forEach(({ id, code }) => {
          if (!item.customizations) {
            item.customizations = {}
          }
          if (!item.customizations[code]) {
            item.customizations[code] = {}
          }

          if (!item.customizations[code][id] && item.defaultCustomizations && item.defaultCustomizations[code]) {
            item.customizations[code][id] = { ...item.defaultCustomizations[code] }
          }

          const custom_values = ['board_id', 'board_id_name', 'section_id', 'section_id_name', 'subreddit']

          if (!customizedDataTemp[code]) {
            customizedDataTemp[code] = {}
          }

          if (!customizedDataTemp[code][id]) {
            customizedDataTemp[code][id] = {}
          }

          if (item.customizations[code][id]) {
            custom_values.forEach((key) => {
              if (item.customizations[code][id][key] && !customizedDataTemp[code][id][key]) {
                customizedDataTemp[code][id][key] = item.customizations[code][id][key]
              }
            })
          }
        })

        defaultValuesForPost({
          selectedProfiles,
          post: item,
          dataForCustomizations,
          setDataForCustomizations: () => {},
          allowGetFacebookGroupsOptions: false,
          user,
        })

        const { post } = handleCheckPostForError(item, updatedCustomizationData)
        // eslint-disable-next-line no-param-reassign
        item = post

        if (post.mediaNeedsUpload) {
          mediaNeedsUpload = true
        }

        if (item.hasPostError) {
          postHasErrorsId = item.tempId
        }

        if (item.mediaUploadingError) {
          mediaUploadingError = true
        }

        if (item.boardProblem) {
          socialNetworksWithProblems[PINTEREST] = true
        }
        if (item.subredditProblem) {
          socialNetworksWithProblems[REDDIT] = true
        }

        if (item.selected) {
          if (item.idea) {
            numberOfIdeas++
          } else {
            numberOfPosts++
          }
        }
      })

      socialNetworksWithProblems = Object.keys(socialNetworksWithProblems)

      if (postHasErrorsId) {
        const el = document.getElementById(`bulk-post-${postHasErrorsId}`)
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }

        Alert.error(`Please check your selected posts content.`)
        setListOfPosts([...listOfPosts])
      } else if (mediaUploadingError) {
        Alert.error(`Your media is still uploading. Please wait just a bit longer before continuing.`)
      } else if (socialNetworksWithProblems.length !== 0) {
        setIsBulkSocialProfilesCustomizationsModalOpen({
          customizationData: customizedDataTemp,
          socialNetworksWithProblems,
          selectedPosts,
        })
      } else if (mediaNeedsUpload) {
        setIsMediaUploadDummyModalOpen({ listOfPosts })
      } else {
        setListOfPosts([...listOfPosts])
        setPostSubmitModalOptions({ ...{ isOpen: true, numberOfPosts, numberOfIdeas } })
      }
    }
  }

  const handleSubmitSocialProfilesCustomizations = async ({ customizationData }) => {
    setCustomizationData({ ...customizationData })
    handleClickCloseSocialProfilesCustomizationsModal({ ...customizationData })
    handleClickSubmitPosts({ data: customizationData })
  }

  const rootProps = importFrom === IMPORT_FROM_MULTIPLE_MEDIA ? getRootProps({ isDragActive }) : {}

  const FOUND_PUBLISHING_TIME = PUBLISHING_TIME.find(({ value }) => value === whenToPublish)

  const { type = '', name = '' } = firstSelectedEntity || {}

  let timezoneHoverText = 'Based on timezone settings for your profile group'

  if (type) {
    timezoneHoverText = `Based on your timezone settings for your ${type.toLowerCase()} ${name}`
  }

  return (
    <StyledDialogOverlay disableEnforceFocus open={isOpen} onClose={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" alignItems="center" $isTop>
            <H4 my="m">{step === 1 ? 'Bulk scheduling' : MENU_ITEMS.find(({ type }) => type === importFrom).title}</H4>
            {step === 2 && (
              <Flex alignItems="center">
                {dataToLocalStorageOptions.canSave && dataToLocalStorageOptions.lastTimeSaved > 0 && (
                  <Flex alignItems="center" justifyContent="center" height="100%" mr="m">
                    <Text fontSize="xs" color="secondaryText">
                      {dataToLocalStorageOptions.lastTimeSavedMessage}
                    </Text>
                  </Flex>
                )}
                {selectedEntities.length !== 0 && (
                  <Flex>
                    <Tooltip
                      wrapperComp={
                        <Flex>
                          <Box display={{ mobile: 'block', desktop: 'none' }}>
                            <Text>{formatTimezoneToGMT(selectedTimezone).short}</Text>
                          </Box>
                          <Box display={{ mobile: 'none', desktop: 'block', desktopLarge: 'none' }}>
                            <Text>{formatTimezoneToGMT(selectedTimezone).middle}</Text>
                          </Box>
                          <Box display={{ mobile: 'none', desktopLarge: 'block' }}>
                            <Text>{formatTimezoneToGMT(selectedTimezone).long}</Text>
                          </Box>
                        </Flex>
                      }
                      defaultTopOffset={-87}
                      message={timezoneHoverText}
                      isTriangleVisible={false}
                      left="unset"
                      right="0"
                    />
                  </Flex>
                )}
              </Flex>
            )}
          </StyledDialogEnvironmentWrapper>
          {step === 1 && (
            <Fragment>
              <StyledDialogBodyWrapper flex="1" heigth="100%" width="100%">
                <Scrollbars
                  className="scrollbars-hidden-x"
                  universal
                  renderTrackHorizontal={(props) => (
                    <div {...props} className="track-horizontal" style={{ display: 'none' }} />
                  )}
                  renderThumbHorizontal={(props) => (
                    <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />
                  )}
                >
                  <Grid
                    gridTemplateColumns={{ mobile: 'repeat(2, 1fr)', desktop: 'repeat(3, 1fr)' }}
                    height="100%"
                    width="100%"
                  >
                    {MENU_ITEMS.map(({ title, description, imageComponent, learnMoreLink, buttonText, type }) => (
                      <StyledMenuItemWrapper
                        key={title}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        heigth="100%"
                        p="m"
                      >
                        {imageComponent}
                        <Flex mt="m" justifyContent="center" height="55px">
                          <Text fontSize="l" textAlign="center">
                            {title}
                          </Text>
                        </Flex>
                        <Flex width="265px" height="90px" justifyContent="center">
                          <StyledImportText>
                            {description}{' '}
                            {learnMoreLink && (
                              <StyledLearnMore color="primary" as="a" href={learnMoreLink} target="_blank">
                                Learn more
                              </StyledLearnMore>
                            )}
                          </StyledImportText>
                        </Flex>

                        <Button.Bordered
                          mt="m"
                          onClick={() => {
                            if (featuresEnabled[FEATURE_BULK_SCHEDULING].enabled) {
                              handleClickSelectImport(type)
                            } else {
                              window.location.replace('/pricing')
                            }
                          }}
                        >
                          <Text>{featuresEnabled[FEATURE_BULK_SCHEDULING].enabled ? buttonText : PLAN_UPGRADE}</Text>
                        </Button.Bordered>
                      </StyledMenuItemWrapper>
                    ))}
                  </Grid>
                </Scrollbars>
              </StyledDialogBodyWrapper>

              <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
                <Flex alignItems="center" width="80%" maxWidth="700px">
                  <Flex alignItems="center" minWidth="160px">
                    <Text fontSize="m" mr="m">
                      {selectedProfiles.length ? 'Selected profiles' : 'No profiles selected'}
                    </Text>
                  </Flex>
                  <Box width="440px">
                    <SelectedProfilesSlider
                      selectedProfiles={selectedProfiles}
                      handleClickRemoveProfile={handleClickRemoveProfile}
                    />
                  </Box>
                </Flex>

                <Flex alignItems="center" justifyContent="flex-end" width="20%">
                  <ButtonWithLoading buttonComp={Button.Gray} mr="m" isSmall onClick={handleClickCloseModal}>
                    Close & Cancel
                  </ButtonWithLoading>
                </Flex>
              </StyledDialogEnvironmentWrapper>
            </Fragment>
          )}
          {step === 2 && (
            <Fragment>
              <StyledDialogBodyWrapper position="relative" flex="1" {...rootProps}>
                <StyledFilesWrapper
                  show={isDragActive || uploadingMediasAmount > 0}
                  alignItems="center"
                  justifyContent="center"
                >
                  {uploadingMediasAmount === 0 ? (
                    <Text fontSize="xxl" fontWeight="bold" color={COLOR_CONSTANTS.WHITE}>
                      Drag 'n' drop some files here
                    </Text>
                  ) : (
                    <Flex
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                      height="100%"
                      px="m"
                    >
                      <Text mb="s" fontSize="xxl" fontWeight="bold" color={COLOR_CONSTANTS.WHITE}>
                        {`${uploadingMediasAmount} ${uploadingMediasAmount === 1 ? 'file' : 'files'} left. `}
                        Please wait a moment...
                      </Text>
                      <ClipLoader size="50" color={colors.primary} />
                    </Flex>
                  )}
                </StyledFilesWrapper>
                <Flex height="100%">
                  <EntitySelectorWrapper
                    user={user}
                    entities={entities}
                    selectedProfiles={selectedProfilesForEntitySelector}
                    selectedEntities={selectedEntities}
                    handleSaveSelectedEntities={handleSaveSelectedEntities}
                    handleSaveSelectedProfiles={handleSaveSelectedProfiles}
                    entitySelectorKey={entitySelectorKey}
                    blockedSocialProfileChecker={({ profile }) => {
                      const { network: { code, display } = {}, status } = profile

                      let blocked = false
                      let blockedProfileHoverText = ''

                      let UPDATED_BLOCKED_SOCIAL_PROFILES = [...BLOCKED_SOCIAL_PROFILES]

                      if (importFrom !== IMPORT_FROM_SEARCH_CONTENT) {
                        UPDATED_BLOCKED_SOCIAL_PROFILES = [...BLOCKED_PUBLISHING_SOCIAL_PROFILES]
                      }

                      if (UPDATED_BLOCKED_SOCIAL_PROFILES.includes(code) || status === PROFILE_STATUSES.BLOCKED) {
                        blocked = true
                        blockedProfileHoverText = `${display} is not available for publishing since it requires a video`
                      }

                      return { blocked, blockedProfileHoverText }
                    }}
                    accessLevels={[ACCESS_MANAGE]}
                    permission={PERMISSION_PUBLISH}
                  />
                </Flex>
                <RightColumnWrapper width="100%" height="100%" flexDirection="column">
                  <Flex mt="s" alignItems="center" justifyContent="space-between" height="38px" minHeight="38px">
                    <Flex width="580px" alignItems="center">
                      {importFrom === IMPORT_FROM_RSS && (
                        <Flex width="100%" px="m">
                          <Flex flexDirection="column" width="70%" mr="m">
                            <Input
                              placeholder="Insert the RSS link or a link to the blog"
                              label=""
                              value={rssUrl}
                              onChange={(e) => {
                                setRSSUrl(e.target.value)
                              }}
                              onKeyUp={(e) => {
                                if (e.keyCode === 13 && !isSearchingForPosts) {
                                  handleClickGetRSSPosts()
                                }
                              }}
                              isSmall
                              width="100%"
                            />
                          </Flex>
                          <ButtonWithLoading
                            buttonComp={Button.Gray}
                            height="34px"
                            isLoading={isSearchingForPosts}
                            onClick={handleClickGetRSSPosts}
                            loaderColor={COLOR_CONSTANTS.SALUTE}
                          >
                            <Text fontSize="s">{isSearchingForPosts ? 'Downloading' : ' Import'}</Text>
                          </ButtonWithLoading>
                        </Flex>
                      )}
                      {importFrom === IMPORT_FROM_CSV && (
                        <Flex width="100%" px="m" alignItems="center">
                          <ButtonWithLoading
                            width="190px"
                            buttonComp={Button.Bordered}
                            isLoading={isSearchingForPosts}
                            loaderColor={colors.primary}
                            isSmall
                            onClick={() => {
                              if (!isSearchingForPosts) {
                                inputCSVFileRef.current.click()
                              }
                            }}
                            isDisabled={isSearchingForPosts}
                          >
                            {isSearchingForPosts ? `${processText}Uploading` : 'Upload CSV File'}
                          </ButtonWithLoading>
                          <Link href={CSV_EXAMPLE_LINK} target="_blank" mx="m" color="primary">
                            Download sample
                            <Image src="assets/download_red.svg" width="14px" height="14px" ml="s" />
                          </Link>
                        </Flex>
                      )}
                      {importFrom === IMPORT_FROM_SEARCH_CONTENT && (
                        <Flex width="100%" px="m">
                          <Flex flexDirection="column" width="70%" mr="m">
                            <Input
                              placeholder="Enter company, product or keyword"
                              label=""
                              innerRef={searchLatestNewsInputRef}
                              onKeyUp={(e) => {
                                if (e.keyCode === 13 && !isSearchingForPosts) {
                                  handleClickFindContent()
                                }
                              }}
                              isSmall
                              width="100%"
                            />
                          </Flex>
                          <ButtonWithLoading
                            buttonComp={Button.Gray}
                            height="34px"
                            isLoading={isSearchingForPosts}
                            onClick={handleClickFindContent}
                            loaderColor={COLOR_CONSTANTS.SALUTE}
                          >
                            <Text fontSize="s">{isSearchingForPosts ? 'Searching' : 'Find posts'}</Text>
                          </ButtonWithLoading>
                        </Flex>
                      )}
                      {importFrom === IMPORT_FROM_MULTIPLE_MEDIA && (
                        <Flex width="100%" px="m" alignItems="center">
                          <DropdownMenu
                            zIndex={16}
                            WrapperComp={
                              <AddMediaWrapper alignItems="center">
                                <ImageComponent source="/assets/plus.svg" mr="s" />
                                <Text color="primary">Add images</Text>
                              </AddMediaWrapper>
                            }
                            isDismissedOnClickInside
                            left={0}
                            isTriangleVisible={false}
                          >
                            <DropdownMenu.Item
                              label="Upload from computer"
                              labelMaxWidth="200px"
                              iconName="Upload"
                              iconWidth="14px"
                              iconColor={COLOR_CONSTANTS.COSMIC_ENERGY}
                              onClick={() => {
                                checkIfMediaCanBeAttached({
                                  func: () => {
                                    inputImageFileRef.current.click()
                                  },
                                })
                              }}
                              isCursorPointer
                            />

                            <Divider height="1px" />

                            <DropdownMenu.Item
                              label="Choose from Library"
                              iconName="VistaSocialPicture"
                              iconWidth="14px"
                              iconColor={COLOR_CONSTANTS.COSMIC_ENERGY}
                              labelMaxWidth="200px"
                              onClick={() => {
                                checkIfMediaCanBeAttached({
                                  func: () => {
                                    handleClickOpenMediaLibraryModal({ type: POST_IMAGE })
                                  },
                                })
                              }}
                              isCursorPointer
                            />

                            <DropdownMenu.Item
                              label="Discover"
                              labelMaxWidth="200px"
                              iconName="Search"
                              iconWidth="14px"
                              iconColor={COLOR_CONSTANTS.COSMIC_ENERGY}
                              onClick={() => {
                                checkIfMediaCanBeAttached({
                                  func: () => {
                                    handleClickOpenMediaDiscoverModal({ type: POST_IMAGE })
                                  },
                                })
                              }}
                              isCursorPointer
                            />

                            <Divider height="1px" />

                            <DropdownMenu.Item
                              label="Google drive"
                              iconName="GoogleDrive"
                              iconWidth="14px"
                              onClick={() => {
                                checkIfMediaCanBeAttached({
                                  func: () => {
                                    handleClickGoogleDrive({ type: POST_IMAGE })
                                  },
                                })
                              }}
                              isCursorPointer
                            />

                            <DropdownMenu.Item
                              label="Dropbox"
                              iconName="Dropbox"
                              iconWidth="14px"
                              onClick={() => {
                                checkIfMediaCanBeAttached({
                                  func: () => {
                                    handleClickDropBox({ type: POST_IMAGE })
                                  },
                                })
                              }}
                              isCursorPointer
                            />
                            <DropdownMenu.Item
                              label="OneDrive"
                              iconName="OneDrive"
                              iconWidth="18px"
                              onClick={() => {
                                checkIfMediaCanBeAttached({
                                  func: () => {
                                    handleClickOneDrive({ type: POST_IMAGE })
                                  },
                                })
                              }}
                              isCursorPointer
                            />
                          </DropdownMenu>

                          <DropdownMenu
                            zIndex={16}
                            WrapperComp={
                              <AddMediaWrapper alignItems="center" ml="m">
                                <ImageComponent source="/assets/plus.svg" mr="s" />
                                <Text color="primary">Add videos</Text>
                              </AddMediaWrapper>
                            }
                            isDismissedOnClickInside
                            left={0}
                            isTriangleVisible={false}
                          >
                            <DropdownMenu.Item
                              label="Upload from computer"
                              labelMaxWidth="200px"
                              iconName="Upload"
                              iconWidth="14px"
                              iconColor={COLOR_CONSTANTS.COSMIC_ENERGY}
                              onClick={() => {
                                checkIfMediaCanBeAttached({
                                  func: () => {
                                    inputVideoFileRef.current.click()
                                  },
                                })
                              }}
                              isCursorPointer
                            />

                            <Divider height="1px" />

                            <DropdownMenu.Item
                              label="Choose from Library"
                              iconName="VistaSocialPicture"
                              iconWidth="14px"
                              iconColor={COLOR_CONSTANTS.COSMIC_ENERGY}
                              labelMaxWidth="200px"
                              onClick={() => {
                                checkIfMediaCanBeAttached({
                                  func: () => {
                                    handleClickOpenMediaLibraryModal({ type: POST_VIDEO })
                                  },
                                })
                              }}
                              isCursorPointer
                            />

                            <DropdownMenu.Item
                              label="Discover"
                              labelMaxWidth="200px"
                              iconName="Search"
                              iconWidth="14px"
                              iconColor={COLOR_CONSTANTS.COSMIC_ENERGY}
                              onClick={() => {
                                checkIfMediaCanBeAttached({
                                  func: () => {
                                    handleClickOpenMediaDiscoverModal({ type: POST_VIDEO })
                                  },
                                })
                              }}
                              isCursorPointer
                            />

                            <Divider height="1px" />

                            <DropdownMenu.Item
                              label="Google drive"
                              iconName="GoogleDrive"
                              iconWidth="14px"
                              onClick={() => {
                                checkIfMediaCanBeAttached({
                                  func: () => {
                                    handleClickGoogleDrive({ type: POST_VIDEO })
                                  },
                                })
                              }}
                              isCursorPointer
                            />

                            <DropdownMenu.Item
                              label="Dropbox"
                              iconName="Dropbox"
                              iconWidth="14px"
                              onClick={() => {
                                checkIfMediaCanBeAttached({
                                  func: () => {
                                    handleClickDropBox({ type: POST_VIDEO })
                                  },
                                })
                              }}
                              isCursorPointer
                            />
                            <DropdownMenu.Item
                              label="OneDrive"
                              iconName="OneDrive"
                              iconWidth="18px"
                              onClick={() => {
                                checkIfMediaCanBeAttached({
                                  func: () => {
                                    handleClickOneDrive({ type: POST_VIDEO })
                                  },
                                })
                              }}
                              isCursorPointer
                            />
                          </DropdownMenu>

                          <DropdownMenu
                            zIndex={16}
                            WrapperComp={
                              <AddMediaWrapper alignItems="center" ml="m">
                                <ImageComponent source="/assets/plus.svg" mr="s" />
                                <Text color="primary">Add documents</Text>
                              </AddMediaWrapper>
                            }
                            isDismissedOnClickInside
                            left={0}
                            isTriangleVisible={false}
                          >
                            <DropdownMenu.Item
                              label="Upload from computer"
                              labelMaxWidth="200px"
                              iconName="Upload"
                              iconWidth="14px"
                              iconColor={COLOR_CONSTANTS.COSMIC_ENERGY}
                              onClick={() => {
                                checkIfMediaCanBeAttached({
                                  func: () => {
                                    inputDocumentFileRef.current.click()
                                  },
                                })
                              }}
                              isCursorPointer
                            />

                            <Divider height="1px" />

                            <DropdownMenu.Item
                              label="Choose from Library"
                              iconName="VistaSocialPicture"
                              iconWidth="14px"
                              iconColor={COLOR_CONSTANTS.COSMIC_ENERGY}
                              labelMaxWidth="200px"
                              onClick={() => {
                                checkIfMediaCanBeAttached({
                                  func: () => {
                                    handleClickOpenMediaLibraryModal({ type: POST_DOCUMENT })
                                  },
                                })
                              }}
                              isCursorPointer
                            />

                            <Divider height="1px" />

                            <DropdownMenu.Item
                              label="Google drive"
                              iconName="GoogleDrive"
                              iconWidth="14px"
                              onClick={() => {
                                checkIfMediaCanBeAttached({
                                  func: () => {
                                    handleClickGoogleDrive({ type: POST_DOCUMENT })
                                  },
                                })
                              }}
                              isCursorPointer
                            />

                            <DropdownMenu.Item
                              label="Dropbox"
                              iconName="Dropbox"
                              iconWidth="14px"
                              onClick={() => {
                                checkIfMediaCanBeAttached({
                                  func: () => {
                                    handleClickDropBox({ type: POST_DOCUMENT })
                                  },
                                })
                              }}
                              isCursorPointer
                            />
                            <DropdownMenu.Item
                              label="OneDrive"
                              iconName="OneDrive"
                              iconWidth="18px"
                              onClick={() => {
                                checkIfMediaCanBeAttached({
                                  func: () => {
                                    handleClickOneDrive({ type: POST_DOCUMENT })
                                  },
                                })
                              }}
                              isCursorPointer
                            />
                          </DropdownMenu>
                        </Flex>
                      )}
                      {(importFrom === IMPORT_FROM_INSTAGRAM_HASHTAG || importFrom === IMPORT_FROM_INSTAGRAM_USER) && (
                        <Flex width="100%" px="m">
                          <Flex flexDirection="column" width="70%" mr="m">
                            <Input
                              placeholder={`Enter ${
                                importFrom === IMPORT_FROM_INSTAGRAM_HASHTAG ? 'hashtag' : 'username'
                              }`}
                              label=""
                              innerRef={searchInstagramInputRef}
                              onKeyUp={(e) => {
                                if (e.keyCode === 13 && !isSearchingForPosts) {
                                  handleClickFindMedia()
                                }
                              }}
                              isSmall
                              width="100%"
                            />
                          </Flex>
                          <ButtonWithLoading
                            buttonComp={Button.Gray}
                            height="34px"
                            isLoading={isSearchingForPosts}
                            onClick={handleClickFindMedia}
                            loaderColor={COLOR_CONSTANTS.SALUTE}
                          >
                            <Text fontSize="s">{isSearchingForPosts ? 'Searching' : 'Find posts'}</Text>
                          </ButtonWithLoading>
                        </Flex>
                      )}
                    </Flex>

                    {listOfPosts.length !== 0 && (
                      <Flex alignItems="center">
                        <Flex width="200px">
                          <DropDown
                            onChange={(option) => {
                              if (option.value === QUEUE_LAST || option.value === QUEUE_NEXT) {
                                if (featuresEnabled[FEATURE_ADD_TO_QUEUE].enabled) {
                                  setWhenToPublish(option.value)
                                } else {
                                  Alert.error(featuresEnabled[FEATURE_ADD_TO_QUEUE].description, {
                                    timeout: 5000,
                                  })
                                }
                              } else if (option.value === IDEA) {
                                const canManage = hasGlobalAccessManage({ user, permission: PERMISSION_IDEAS })

                                if (canManage) {
                                  setSelectedQueueLabel(null)
                                  setWhenToPublish(option.value)
                                } else {
                                  Alert.error(PERMISSION_ERRORS[PERMISSION_IDEAS].NO_ACCESS, { timeout: 5000 })
                                }
                              } else {
                                setSelectedQueueLabel(null)
                                setWhenToPublish(option.value)
                              }
                            }}
                            value={FOUND_PUBLISHING_TIME}
                            options={PUBLISHING_TIME.filter(({ type }) => type === importFrom || !type)}
                            width="100%"
                          />
                        </Flex>
                        <Flex>
                          <Flex>
                            <Flex
                              mx="m"
                              onMouseEnter={(e) => {
                                tooltipRef.current.handleShowTooltip({
                                  contentComp: (
                                    <Box width="300px" p="s">
                                      <Text>{FOUND_PUBLISHING_TIME ? FOUND_PUBLISHING_TIME.description : ''}</Text>
                                    </Box>
                                  ),
                                  wrapperComp: e.currentTarget,
                                })
                              }}
                              onMouseLeave={() => {
                                tooltipRef.current.handleHideTooltip()
                              }}
                            >
                              <QuestionMarkOutlined />
                            </Flex>
                          </Flex>
                        </Flex>

                        {(whenToPublish === QUEUE_LAST || whenToPublish === QUEUE_NEXT) && usedQueueLabels.length > 1 && (
                          <Flex flexDirection="column" width="200px" mr="m">
                            <DropDown
                              onChange={(option) => {
                                if (featuresEnabled[FEATURE_QUEUE_LABELS].enabled) {
                                  setSelectedQueueLabel({ ...option })
                                } else {
                                  Alert.error(featuresEnabled[FEATURE_QUEUE_LABELS].description, {
                                    timeout: 5000,
                                  })
                                }
                              }}
                              value={
                                selectedQueueLabel
                                  ? usedQueueLabels.find(({ value }) => value === selectedQueueLabel.value) ||
                                    DEFAULT_QUEUE_LABEL
                                  : DEFAULT_QUEUE_LABEL
                              }
                              options={usedQueueLabels}
                              width="100%"
                            />
                          </Flex>
                        )}
                      </Flex>
                    )}
                  </Flex>
                  {isSearchingForPosts ? (
                    <Flex alignItems="center" justifyContent="center" width="100%" height="100%" px="m">
                      <ClipLoader size="50" color={colors.primary} />
                    </Flex>
                  ) : (
                    <Flex flexDirection="column" height="calc(100% - 50px)" position="relative">
                      <Flex flexDirection="column" height="100%" position="relative">
                        <Scrollbars
                          className="scrollbars-hidden-x"
                          universal
                          renderTrackHorizontal={(props) => (
                            <div {...props} className="track-horizontal" style={{ display: 'none' }} />
                          )}
                          renderThumbHorizontal={(props) => (
                            <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />
                          )}
                        >
                          {listOfErrors.length > 0 && (
                            <StyledWarningWrapper px="m" py="s" mt="m" mx="m" flexDirection="column">
                              <Text color={COLOR_CONSTANTS.FLUORESCENT_RED} fontWeight="bold">
                                Some items could not be imported:
                              </Text>
                              <StyledList>
                                {listOfErrors.map((item, index) => (
                                  <li key={index}>
                                    <Text as="span" color={COLOR_CONSTANTS.FLUORESCENT_RED}>
                                      {item}
                                    </Text>
                                  </li>
                                ))}
                              </StyledList>
                            </StyledWarningWrapper>
                          )}

                          {listOfPosts.length !== 0 && (
                            <Flex flexDirection="column" height="100%">
                              <StyledFlex alignItems="center" px="m" pt="m" pb="s" justifyContent="space-between">
                                <Flex alignItems="center">
                                  <StyledBox onClick={() => handleClickSelectAllPostsForPublish({ type: 'select' })}>
                                    <Text color="secondaryText" fontSize="xs">
                                      Select all
                                    </Text>
                                  </StyledBox>
                                  <Text color="secondaryText" fontSize="xs">
                                    &nbsp; · &nbsp;
                                  </Text>
                                  <StyledBox onClick={() => handleClickSelectAllPostsForPublish({ type: 'unselect' })}>
                                    <Text color="secondaryText" fontSize="xs">
                                      Clear selection
                                    </Text>
                                  </StyledBox>
                                </Flex>
                                <Flex alignItems="center">
                                  <Text color="secondaryText" fontSize="xs">
                                    {`${numberOfSelectedPosts} ${
                                      numberOfSelectedPosts === 1 ? 'post' : 'posts'
                                    } selected`}
                                  </Text>
                                </Flex>
                              </StyledFlex>
                              <Scrollbars universal>
                                <Flex flexDirection="column">
                                  <Table
                                    handleClickEditPost={handleClickEditPost}
                                    setListOfPosts={setListOfPosts}
                                    data={listOfPosts}
                                    whenToPublish={whenToPublish}
                                    importFrom={importFrom}
                                    selectedQueueLabel={selectedQueueLabel}
                                  />
                                </Flex>
                              </Scrollbars>
                            </Flex>
                          )}
                        </Scrollbars>
                      </Flex>
                    </Flex>
                  )}
                </RightColumnWrapper>
              </StyledDialogBodyWrapper>

              <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
                <Flex alignItems="center" width="80%" maxWidth="700px">
                  <Flex alignItems="center" minWidth="160px">
                    <Text fontSize="m" mr="m">
                      {selectedProfiles.length ? 'Selected profiles' : 'No profiles selected'}
                    </Text>
                  </Flex>
                  <Box mx="m" width="440px">
                    <SelectedProfilesSlider
                      selectedProfiles={selectedProfiles}
                      handleClickRemoveProfile={handleClickRemoveProfile}
                    />
                  </Box>
                </Flex>
                <Flex alignItems="center" justifyContent="flex-end">
                  <Button.Gray ml="m" onClick={handleClickPreviousStep} isSmall isDisabled={isUpdatingPosts}>
                    Previous
                  </Button.Gray>
                  <ButtonWithLoading ml="m" isSmall onClick={handleClickSubmitPosts} isLoading={isUpdatingPosts}>
                    Next
                  </ButtonWithLoading>
                </Flex>
              </StyledDialogEnvironmentWrapper>
            </Fragment>
          )}
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal} isDisabled={isUpdatingPosts}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
        <input
          hidden
          type="file"
          ref={inputCSVFileRef}
          onChange={(e) => handleCSVFileChange(e)}
          accept=".csv"
          onClick={(event) => {
            event.target.value = null
          }}
        />

        {bulkEditPostModalOptions.isOpen && (
          <BulkEditPostModal
            user={user}
            isOpen={bulkEditPostModalOptions.isOpen}
            selectedProfiles={selectedProfiles}
            selectedEntities={selectedEntities}
            selectedTimezone={selectedTimezone}
            postForEdit={bulkEditPostModalOptions.postForEdit}
            handleDismiss={handleClickCloseEditPostModal}
            handleUpdatePost={handleUpdatePost}
            dataForCustomizations={dataForCustomizations}
            whenToPublish={whenToPublish}
            usedQueueLabels={usedQueueLabels}
            featuresEnabled={featuresEnabled}
            hasTimeIdeaOption
          />
        )}

        {postSubmitModalOptions.isOpen && (
          <BulkSubmitPostModal
            user={user}
            isOpen={postSubmitModalOptions.isOpen}
            handleDismiss={handleClickClosePostSubmitModal}
            handleSubmit={handleSubmitPosts}
            numberOfPosts={postSubmitModalOptions.numberOfPosts}
            numberOfIdeas={postSubmitModalOptions.numberOfIdeas}
            isPostSubmitting={isUpdatingPosts}
            handleUpdateEntities={handleUpdateEntities}
            selectedProfiles={selectedProfiles}
            selectedEntities={selectedEntities}
            workflow={workflow}
            setWorkflow={setWorkflow}
          />
        )}

        {isBulkSocialProfilesCustomizationsModalOpen && (
          <BulkSocialProfilesCustomizationsModal
            handleDismiss={handleClickCloseSocialProfilesCustomizationsModal}
            handleSubmit={handleSubmitSocialProfilesCustomizations}
            isOpen={!!isBulkSocialProfilesCustomizationsModalOpen}
            selectedProfiles={selectedProfiles}
            data={isBulkSocialProfilesCustomizationsModalOpen}
            customizationData={isBulkSocialProfilesCustomizationsModalOpen.customizationData}
          />
        )}

        {mediaLibraryOptions.isOpen && (
          <MediaLibraryModal
            user={user}
            handleDismiss={handleClickCloseMediaLibraryModal}
            isOpen={mediaLibraryOptions.isOpen}
            type={mediaLibraryOptions.type}
            handleClickAddMedias={handleClickAddMedias}
            postMedias={mediaLibraryOptions.postMedias}
            selectedEntities={selectedEntities}
            selectedProfiles={selectedProfiles}
            maxImageAttachmentsAllowed={100}
            maxVideoAttachmentsAllowed={100}
            maxDocumentAttachmentsAllowed={100}
          />
        )}

        {mediaDiscoverOptions.isOpen && (
          <MediaDiscoverModal
            handleDismiss={handleClickCloseMediaDiscoverModal}
            isOpen={mediaDiscoverOptions.isOpen}
            type={mediaDiscoverOptions.type}
            handleClickAddMedias={handleClickAddMedias}
            postMedias={mediaDiscoverOptions.postMedias}
          />
        )}

        {mediaSettingsOptions.isOpen && (
          <MediaSettings
            user={user}
            uploadedMedias={mediaSettingsOptions.uploadedMedias}
            isOpen={mediaSettingsOptions.isOpen}
            handleDismiss={handleClickCloseMediaSettingsModal}
            handleUpdateUploadedMedias={handleUpdateUploadedMedias}
            selectedEntities={selectedEntities}
            selectedProfiles={selectedProfiles}
          />
        )}

        {isMediaUploadDummyModalOpen && (
          <MediaUploadDummy
            isOpen={!!isMediaUploadDummyModalOpen}
            data={isMediaUploadDummyModalOpen}
            uploadMediaToServerByStringData={uploadMediaToServerByStringData}
            isSettingsModalOpen={mediaSettingsOptions.isOpen}
          />
        )}

        <input
          hidden
          type="file"
          multiple
          ref={inputImageFileRef}
          onChange={(e) => handlePostImageFileChange(e)}
          accept={IMAGE_EXTENSIONS.join(',')}
          onClick={(event) => {
            event.target.value = null
          }}
        />
        <input
          hidden
          type="file"
          multiple
          ref={inputVideoFileRef}
          onChange={(e) => handlePostVideoFileChange(e)}
          accept={VIDEO_EXTENSIONS.join(',')}
          onClick={(event) => {
            event.target.value = null
          }}
        />
        <input
          hidden
          type="file"
          multiple
          ref={inputDocumentFileRef}
          onChange={(e) => handlePostDocumentFileChange(e)}
          accept={DOCUMENT_EXTENSIONS.join(',')}
          onClick={(event) => {
            event.target.value = null
          }}
        />
        <CalendarItemTooltip ref={tooltipRef} />
      </Box>
    </StyledDialogOverlay>
  )
}

BulkModal.defaultProps = {
  selectedEntities: [],
  entities: [],
  selectedProfiles: [],
  dataForCustomizations: {},
  handleUpdateEntities: () => {},
  handleModalSuccessCallback: () => {},
  handleClickReloadCalendar: () => {},
  specialWhenToPublish: '',
}

BulkModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  entities: PropTypes.array,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  confirm: PropTypes.func.isRequired,
  dataForCustomizations: PropTypes.object,
  handleUpdateEntities: PropTypes.func,
  handleModalSuccessCallback: PropTypes.func,
  handleClickReloadCalendar: PropTypes.func,
  specialWhenToPublish: PropTypes.string,
}

BulkModal.displayName = 'BulkModal'

export default withConfirm(BulkModal)
